import { startLongTaskObserver } from './utils';

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
let resolveTti: (value: number) => void = (_v) => {};
let observerStarted = false;

/**
 * Initialize module state and return the promise a consumer can use to wait
 * for the TTI event.
 */
function initTtiPromise(): Promise<number> {
	return new Promise((resolve) => {
		let hasResolved = false;
		const callback = (ttiResult: number) => {
			if (hasResolved) return;
			hasResolved = true;
			resolve(ttiResult);
		};

		resolveTti = callback;
	});
}

let ttiPromise: Promise<number> | null = initTtiPromise();

/**
 * Reset the TTI observer module state for further runs.
 *
 * After calling this, `getTTIPromise` will return a new promise waiting for
 * the next window without long-tasks.
 */
export function resetTTIObserver() {
	ttiPromise = initTtiPromise();
	observerStarted = false;
}

/**
 * Installs a long-task PerformanceObserver and watches for TTI (a 5s idle time
 * window without long-tasks). At that time, TTI is reported via the
 * `getTTIValue` promise.
 */
export function startTTIObserver() {
	observerStarted = true;
	let cleanupObserver: (() => void) | null = null;
	let lastSeenEventTime = performance.now();

	const callback = () => {
		cleanupObserver?.();
		resolveTti(lastSeenEventTime);
	};

	let timeoutId = setTimeout(callback, 5000);
	cleanupObserver = startLongTaskObserver(() => {
		lastSeenEventTime = performance.now();
		clearTimeout(timeoutId);
		timeoutId = setTimeout(callback, 5000);
	});
}

/**
 * Get the timestamp for TTI, as defined by Google. To be used on **synthetic
 * environments/labs ONLY**.
 */
export function getTTIValue(): Promise<number> {
	const promise = ttiPromise ?? initTtiPromise();
	if (!observerStarted) {
		startTTIObserver();
	}
	return promise;
}
