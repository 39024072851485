import { GlobalPageLoadExperience, payloadPublisher } from '@atlassian/ufo';
import { getInteractionId } from '@atlassian/ufo-interaction-id-context';
import { addMetadata } from '@atlassian/ufo-interaction-metrics';

/**
 * Adds the specified UFO metric configuration to the interaction.
 *
 * https://product-fabric.atlassian.net/browse/UFO-362
 *
 * WARNING: This function is temporary and will be removed once rUFO deals with FMP natively.
 * For now this exists to match behaviour in UFO v1.
 *
 * @returns void
 */
export async function addUFOSSRDoneAsFMPToInteraction() {
	const interactionId = getInteractionId();
	const currentInteractionId = interactionId.current;
	if (!currentInteractionId) {
		return;
	}

	// Use UFO SSR mark as FMP config for rUFO if it exists, temporarily.
	const data = await GlobalPageLoadExperience.exportData();
	const fmpMark =
		data.metrics.marks.find((mark) => mark.name === 'fmp') || payloadPublisher.ssr?.getDoneMark();

	if (data.initial && fmpMark) {
		addMetadata(currentInteractionId, {
			__legacy__ufoConfigSSRDoneAsFmp: true,
		});
	}
}
