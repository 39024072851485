import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment, useMutation } from 'react-relay';
import type { RecordSourceSelectorProxy } from 'relay-runtime';
import FavouriteButtonStateless from '@atlassian/jira-favourite-button-stateless/src';
import { useChangeFavourite } from '@atlassian/jira-favourite-change-provider/src';
import { useFlagsService } from '@atlassian/jira-flags';
import { getAriConfig } from '@atlassian/jira-platform-ari';
import type { favouriteCell_directoryBaseV3_FavouriteCellOld_favouriteValueRef$key } from '@atlassian/jira-relay/src/__generated__/favouriteCell_directoryBaseV3_FavouriteCellOld_favouriteValueRef.graphql';
import type { favouriteCell_directoryBaseV3_Mutation } from '@atlassian/jira-relay/src/__generated__/favouriteCell_directoryBaseV3_Mutation.graphql';
import type { favouriteCell_directoryBaseV3_queryRef$key } from '@atlassian/jira-relay/src/__generated__/favouriteCell_directoryBaseV3_queryRef.graphql';
import messages from './messages';

interface OldProps {
	id: string;
	favouriteItemName?: string | undefined;
	favouriteValueRef: favouriteCell_directoryBaseV3_FavouriteCellOld_favouriteValueRef$key;
	onClick?: (data: { isFavourite: boolean }) => void;
}

type Props = {
	queryRef: favouriteCell_directoryBaseV3_queryRef$key;
	onClick: (data: { isFavourite: boolean }) => void;
};

export const FavouriteCell = ({ queryRef, onClick }: Props) => {
	const { changeFavouriteMutation } = useChangeFavourite();

	const { id, name, isFavourite } = useFragment(
		graphql`
			fragment favouriteCell_directoryBaseV3_queryRef on JiraProject {
				id
				name
				isFavourite
			}
		`,
		queryRef,
	);

	const handleClick = useCallback(() => {
		onClick?.({ isFavourite: !isFavourite });
		changeFavouriteMutation({ id, value: !isFavourite, typename: 'JiraProject' });
	}, [onClick, isFavourite, changeFavouriteMutation, id]);

	return (
		<ContentWrapper>
			<FavouriteButtonStateless
				onClick={handleClick}
				isShownInList
				isFavourite={isFavourite}
				favouriteItemName={name}
			/>
		</ContentWrapper>
	);
};

export const FavouriteCellOld = ({
	id: entityId,
	favouriteItemName,
	favouriteValueRef,
	onClick,
}: OldProps) => {
	const { showFlag } = useFlagsService();
	const { updateFavouriteState } = useChangeFavourite();
	const { __id: recordId, isFavourite } = useFragment(
		graphql`
			fragment favouriteCell_directoryBaseV3_FavouriteCellOld_favouriteValueRef on JiraFavouriteValue {
				__id
				isFavourite
			}
		`,
		favouriteValueRef,
	);

	const [commit] = useMutation<favouriteCell_directoryBaseV3_Mutation>(graphql`
		mutation favouriteCell_directoryBaseV3_Mutation($entityId: ID!, $isFavourite: Boolean!) {
			jira {
				setEntityIsFavourite(input: { entityId: $entityId, isFavourite: $isFavourite })
					@optIn(to: "JiraFavourite") {
					success
				}
			}
		}
	`);

	const updateFavouriteRecord = useCallback(
		(store: RecordSourceSelectorProxy) => {
			const { resourceId, resourceType } = getAriConfig(entityId);
			const favouriteEntityRecord = store.get(recordId);

			if (resourceType === 'project') {
				updateFavouriteState({ id: resourceId, type: 'projects', value: !isFavourite }); // sync global context provider
			}
			favouriteEntityRecord?.setValue(!isFavourite, 'isFavourite');
		},
		[isFavourite, recordId, entityId, updateFavouriteState],
	);

	const handleClick = useCallback(() => {
		onClick &&
			onClick({
				isFavourite: !isFavourite,
			});

		commit({
			variables: {
				entityId,
				isFavourite: !isFavourite,
			},
			optimisticUpdater: updateFavouriteRecord,
			updater: updateFavouriteRecord,
			onCompleted: (data) => {
				if (!data?.jira?.setEntityIsFavourite?.success) {
					showFlag({
						type: 'error',
						title: messages.title,
						description: messages.description,
					});
				}
			},
		});
	}, [onClick, isFavourite, commit, entityId, updateFavouriteRecord, showFlag]);

	return (
		<ContentWrapper>
			<FavouriteButtonStateless
				onClick={handleClick}
				isShownInList
				isFavourite={isFavourite}
				favouriteItemName={favouriteItemName}
			/>
		</ContentWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
});
