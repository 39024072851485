import type { ReactNode } from 'react';
import difference from 'lodash/difference';
import type { StoreActionApi } from 'react-sweet-state';
import type { OptionGroupType, State } from '../../common/types';
import getAdminSections from '../../services/administration';
import { buildAdminSections } from './utils';

type StoreApi = StoreActionApi<State>;

// Overwrites any existing set of shortcuts with the same key
export const registerShortcuts =
	(shortcuts: OptionGroupType[]) =>
	({ getState, setState }: StoreApi) => {
		const currentState = getState();
		const newShortcuts = shortcuts.reduce(
			(acc, current) => {
				acc[current.key] = current;
				return acc;
			},
			{ ...currentState.shortcuts },
		);

		setState({
			...currentState,
			shortcuts: newShortcuts,
			isShortcutsLoading: false,
		});
	};

export const setShortcutsDialogVisibility =
	(isVisible: boolean) =>
	({ getState, setState }: StoreApi) => {
		setState({
			...getState(),
			isShortcutsDialogVisible: isVisible,
		});
	};

export const setShortcutsLoading =
	(isLoading: boolean) =>
	({ getState, setState }: StoreApi) => {
		setState({
			...getState(),
			isShortcutsLoading: isLoading,
		});
	};

export const setHasError =
	(hasError: boolean) =>
	({ getState, setState }: StoreApi) => {
		const currentState = getState();

		setState({
			...currentState,
			hasError,
		});
	};

export const setHeader =
	(header: null | ReactNode, title?: string) =>
	({ getState, setState }: StoreApi) => {
		const currentState = getState();

		setState({
			...currentState,
			header,
			title,
		});
	};

export const fetchAdminSections =
	() =>
	async ({ setState, getState }: StoreApi) => {
		const currentState = getState();
		setState({
			...currentState,
			isAdminLoading: true,
		});

		const result = await getAdminSections();
		const shortcuts = result ? buildAdminSections(result) : [];

		const newState = getState();
		setState({
			...newState,
			shortcuts: {
				...newState.shortcuts,
				admin: {
					...newState.shortcuts.admin,
					key: 'admin',
					options: shortcuts,
					isLoaded: true,
				},
			},
			hasError: currentState.hasError || !result,
			isAdminLoading: false,
		});
	};

export const setSelectedItemKey =
	(item: null | string) =>
	({ setState, getState }: StoreApi) => {
		setState({
			...getState(),
			selectedItemKey: item,
		});
	};

export const dropShortcutsByKeys =
	(keys: string[]) =>
	({ getState, setState }: StoreApi) => {
		if (!keys.length) {
			return;
		}

		const { shortcuts, ...rest } = getState();

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const newShortcuts = difference(Object.keys(shortcuts), keys).reduce<Record<string, any>>(
			(acc, key) => {
				acc[key] = shortcuts[key];

				return acc;
			},
			{},
		);

		setState({
			...rest,
			shortcuts: newShortcuts,
			isShortcutsLoading: false,
		});
	};
