import React from 'react';
import OnMountAndUpdate from '@atlassian/jira-common-util-op-triggers/src/on-mount-and-update';
import TagMauEvent from '../../tag-mau';
import { subProductFromProjectType } from '../transformations';
import setCurrentSubProduct from './set-current-sub-product-and-solutions';
import { isSubProductProps, type Props } from './types';

export type { Props };

export const SubProductUpdater = ({ allowMonolithDeferral = false, ...props }: Props) => {
	const subProduct = isSubProductProps(props)
		? props.subProduct
		: subProductFromProjectType(props.projectType);

	return (
		<>
			<OnMountAndUpdate op={setCurrentSubProduct} args={[subProduct, allowMonolithDeferral]} />
			<TagMauEvent subProduct={subProduct} />
		</>
	);
};
