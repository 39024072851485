import type { AdminQuickSearchResponse, ShortcutOption } from '../../common/types';

export const buildAdminSections = (
	parent: AdminQuickSearchResponse,
	result: ShortcutOption[] = [],
) => {
	if (parent.sections && parent.sections.length) {
		parent.sections.forEach((s) => buildAdminSections(s, result));
	}
	if (parent.items && parent.items.length) {
		parent.items.forEach((i) =>
			result.push({
				key: i.key,
				label: i.label,
				// @ts-expect-error - TS2322 - Type '() => void' is not assignable to type '(event?: Event | undefined) => Promise<undefined> | undefined'.
				onClick: () => {
					window.location.href = i.linkUrl;
				},
			}),
		);
	}
	return result;
};
