import React, { useEffect, useState, useRef, memo } from 'react';
import { ScreenReaderText } from '../../common/ui';
import type { LiveMode } from '../../common/ui/types';

type Props = {
	message: string;
	liveMode?: LiveMode;
	shouldAnnounce?: boolean;
	testId?: string;
};

const NON_BREAKING_SPACE = '\u00A0';

export const AnnouncerV2 = memo(
	({ message: originalMessage, liveMode = 'polite', shouldAnnounce = true, testId }: Props) => {
		const previousMessage = useRef('');
		const setAlternatePolite = useRef(false);
		const addSuffix = useRef(false);

		const [oldMessage, setOldMessage] = useState('');
		const [newMessage, setNewMessage] = useState('');

		useEffect(() => {
			let message = shouldAnnounce ? originalMessage : NON_BREAKING_SPACE;
			if (previousMessage.current === message) {
				addSuffix.current = !addSuffix.current;
				if (addSuffix.current) {
					message += NON_BREAKING_SPACE;
				}
			}
			setOldMessage(setAlternatePolite.current ? '' : message);
			setNewMessage(setAlternatePolite.current ? message : '');
			if (shouldAnnounce) {
				setAlternatePolite.current = !setAlternatePolite.current;
			}
			previousMessage.current = originalMessage;
		}, [originalMessage, shouldAnnounce]);

		// 2 aria-live containers needed to prevent bugs in browsers that will stop
		// announcing updates to the same div if these are too repetitive.
		return (
			<div data-testid={testId}>
				<ScreenReaderText
					role="status"
					aria-live={liveMode}
					aria-relevant="additions text"
					aria-atomic="true"
				>
					{oldMessage}
				</ScreenReaderText>
				<ScreenReaderText
					role="status"
					aria-live={liveMode}
					aria-relevant="additions text"
					aria-atomic="true"
				>
					{newMessage}
				</ScreenReaderText>
			</div>
		);
	},
);
