import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewAllTemplates: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		id: 'project-list-template-picker.top-template-picker.template-gallery.view-all-templates',
		defaultMessage: 'View all templates',
		description: 'Button to open drawer showing all project templates',
	},
	lastCreated: {
		id: 'project-list-template-picker.top-template-picker.template-gallery.last-created',
		defaultMessage: 'Last created',
		description: 'Label for last created template lozenge',
	},
	premium: {
		id: 'project-list-template-picker.top-template-picker.template-gallery.premium',
		defaultMessage: 'Premium',
		description: 'Label for premium template lozenge',
	},
	try: {
		id: 'project-list-template-picker.top-template-picker.template-gallery.try',
		defaultMessage: 'Try',
		description: 'Label for template from unlicensed project in jira project template list',
	},
});
