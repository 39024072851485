import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { keyCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/keyCell_projectsDirectoryV3.graphql';

interface Props {
	project: keyCell_projectsDirectoryV3$key;
}

export const KeyCell = ({ project }: Props) => {
	const { key } = useFragment(
		graphql`
			fragment keyCell_projectsDirectoryV3 on JiraProject {
				key
			}
		`,
		project,
	);

	return <>{key}</>;
};
