/**
 * @generated SignedSource<<b0008c93eac38f70b5b6c5472eb87221>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew$data = {
  readonly projectListViewTemplates: {
    readonly edges: ReadonlyArray<{
      readonly __typename: "JiraProjectListViewTemplateEdge";
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"templateGallery_projectListTemplatePicker_TemplateGalleryNew">;
  readonly " $fragmentType": "topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew";
};
export type topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew$key = {
  readonly " $data"?: topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew$data;
  readonly " $fragmentSpreads": FragmentRefs<"topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "experimentKey",
  "variableName": "experimentKey"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "experimentKey"
    }
  ],
  "kind": "Fragment",
  "name": "topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        },
        (v0/*: any*/)
      ],
      "concreteType": "JiraProjectListViewTemplateConnection",
      "kind": "LinkedField",
      "name": "projectListViewTemplates",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraProjectListViewTemplateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "__typename"
            }
          ]
        }
      ]
    },
    {
      "args": [
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "templateGallery_projectListTemplatePicker_TemplateGalleryNew"
    }
  ],
  "type": "JiraQuery"
};
})();

(node as any).hash = "3eea9a687307719ee44d42b85a317897";

export default node;
