/**
 * @generated SignedSource<<7dd5beec9e663cd3fd54ffad84dea5ac>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type favouriteCell_directoryBaseV3_queryRef$data = {
  readonly id: string;
  readonly isFavourite: boolean | null | undefined;
  readonly name: string;
  readonly " $fragmentType": "favouriteCell_directoryBaseV3_queryRef";
};
export type favouriteCell_directoryBaseV3_queryRef$key = {
  readonly " $data"?: favouriteCell_directoryBaseV3_queryRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"favouriteCell_directoryBaseV3_queryRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "favouriteCell_directoryBaseV3_queryRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "9c716d38d38cde37922be11a8217fcb4";

export default node;
