import {
	PROJECTS_DIRECTORY_EXPERIENCE,
	MANAGE_PROJECTS_DIRECTORY_EXPERIENCE,
} from '@atlassian/jira-projects-directory-v3-utils/src/constants';

export const METRICS_CONFIG = {
	default: {
		sourceName: 'projectsV3',
		experienceKey: PROJECTS_DIRECTORY_EXPERIENCE,
		experienceSource: 'projects-directory-v3',
		errorBoundary: 'spa-app-bootstrap.directories.projects-v3',
		appNameV2: 'directory.projects-v3',
		appNameV3: 'projectsDirectoryV3',
	},
	admin: {
		sourceName: 'projectsManageV3',
		experienceKey: MANAGE_PROJECTS_DIRECTORY_EXPERIENCE,
		experienceSource: 'projects-directory-manage-v3',
		errorBoundary: 'spa-app-bootstrap.directories.projects-manage-v3',
		appNameV2: 'directory.projects-manage-v3',
		appNameV3: 'projectsDirectoryManageV3',
	},
};
