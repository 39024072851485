import { createStore, createHook } from 'react-sweet-state';
import type { State } from '../../common/types';
import * as actions from './actions';

export type Actions = typeof actions;

export const initialState = {
	shortcuts: {
		admin: {
			key: 'admin',
			label: 'ADMIN',
			options: [],
			isLoaded: false,
		},
	},
	isShortcutsDialogVisible: false,
	isShortcutsLoading: false,
	hasError: false,
	isAdminLoading: false,
	selectedItemKey: null,
	header: null,
	title: undefined,
} as const;

export const store = createStore<State, Actions>({
	name: 'shortcuts',
	// @ts-expect-error - TS2322 - Type '{ readonly shortcuts: { readonly admin: { readonly key: "admin"; readonly label: "ADMIN"; readonly options: readonly []; readonly isLoaded: false; }; }; readonly isShortcutsDialogVisible: false; readonly isShortcutsLoading: false; ... 4 more ...; readonly title: undefined; }' is not assignable to type 'State'.
	initialState,
	actions,
});

export const useShortcutsStore = createHook(store);

export const useShortcutsActionsStore = createHook(store, {
	selector: null,
});
