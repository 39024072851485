import log from '@atlassian/jira-common-util-logging/src/log';
import { performGetRequest } from '@atlassian/jira-fetch';
import type { SubProduct } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (subProduct: SubProduct) => {
	performGetRequest(`/rest/internal/2/mauTag/${subProduct}`).catch((err) => {
		log.safeErrorWithoutCustomerData(
			'common.tag-mau.tag-mau-event',
			'Error connecting to monolith mau tag endpoint',
			err,
		);
	});
};
