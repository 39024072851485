import { getInteractionId } from '@atlassian/ufo-interaction-id-context';
import { addMetadata } from '@atlassian/ufo-interaction-metrics';
import type { BM3Config, BM3Metric } from '../types';

/**
 * Adds the specified BM3 metric configuration to the interaction.
 *
 * https://product-fabric.atlassian.net/browse/UFO-323
 *
 * WARNING: This function is temporary and will be removed once UFO deals with FMP natively.
 * For now this exists to match behaviour in BM3.
 *
 * @param metric The BM3 metric configuration to add.
 * @returns void
 */
export function addBM3SSRDoneAsFMPToInteraction(metric: BM3Metric) {
	const interactionId = getInteractionId();
	const currentInteractionId = interactionId.current;
	if (!currentInteractionId) {
		return;
	}

	const data = metric.getData();
	// Use BM3 SSR mark as FMP config for UFO if it exists, temporarily.
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const config = data?.config as BM3Config;

	if (config && data?.isInitial) {
		addMetadata(currentInteractionId, {
			__legacy__bm3ConfigSSRDoneAsFmp: config.ssr?.doneAsFmp || false,
		});
	}
}
