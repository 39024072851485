import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration';
import { ff } from '@atlassian/jira-feature-flagging';

/**
 * Visible only to screenreaders. Use when there is a need
 * to provide more context to a non-sighted user.
 */
export const visuallyHiddenStyles = {
	clip: 'rect(1px, 1px, 1px, 1px)',
	clipPath: 'inset(50%)',
	height: '1px',
	width: '1px',

	margin: '-1px',
	overflow: 'hidden',
	padding: 0,
	position: 'absolute',
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ScreenReaderTextControl = styled.div(visuallyHiddenStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ScreenReaderTextExperiment = styled2.div(visuallyHiddenStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ScreenReaderText = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ScreenReaderTextExperiment,
	ScreenReaderTextControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ScreenReaderCaptionControl = styled.caption(visuallyHiddenStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ScreenReaderCaptionExperiment = styled2.caption(visuallyHiddenStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ScreenReaderCaption = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ScreenReaderCaptionExperiment,
	ScreenReaderCaptionControl,
);
