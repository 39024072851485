import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import {
	FavouriteCellOld as BaseFavouriteCellOld,
	FavouriteCell as BaseFavouriteCell,
} from '@atlassian/jira-directory-base-v3/src/ui/table-cells/favourite-cell';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { favouriteCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/favouriteCell_projectsDirectoryV3.graphql';

interface Props {
	project: favouriteCell_projectsDirectoryV3$key;
}

export const FavouriteCell = ({ project }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'resultsTable',
	});

	const data = useFragment<favouriteCell_projectsDirectoryV3$key>(
		graphql`
			fragment favouriteCell_projectsDirectoryV3 on JiraProject {
				canViewIssue: action(type: VIEW_ISSUES) {
					canPerform
				}
				...favouriteCell_directoryBaseV3_queryRef
				id
				favouriteValue {
					...favouriteCell_directoryBaseV3_FavouriteCellOld_favouriteValueRef
				}
				name
			}
		`,
		project,
	);

	const handleClick = useCallback(() => {
		fireUIAnalytics(analyticsEvent, 'FavouriteButton');
	}, [analyticsEvent]);

	if (getWillShowNav4()) {
		return !data.canViewIssue?.canPerform ? null : (
			<BaseFavouriteCell queryRef={data} onClick={handleClick} />
		);
	}

	// Delete the following during getWillShowNav4 cleanup
	const { id, name, favouriteValue, canViewIssue } = data;

	if (!canViewIssue?.canPerform || !favouriteValue) {
		return null;
	}

	return (
		<BaseFavouriteCellOld
			id={id}
			favouriteItemName={name}
			favouriteValueRef={favouriteValue}
			onClick={handleClick}
		/>
	);
};
