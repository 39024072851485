/**
 * Starts a long-task observer that will call `cb` on each observation.
 * Returns a disposal function
 */
export function startLongTaskObserver(cb: () => void): () => void {
	const observer = new PerformanceObserver(() => {
		cb();
	});

	observer.observe({ type: 'longtask' });

	return () => {
		observer.disconnect();
	};
}
