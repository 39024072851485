import { useState } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers';

const localStorage = createLocalStorageProvider('project-list-template-picker');
const EXPANDED_STATE_KEY = 'isExpanded';

export const useExpandedState = () => {
	const [isExpanded, setIsExpandedState] = useState(
		Boolean(localStorage.get(EXPANDED_STATE_KEY) ?? true),
	);

	const setIsExpanded = (value: boolean) => {
		localStorage.set(EXPANDED_STATE_KEY, value);
		setIsExpandedState(value);
	};

	return {
		isExpanded,
		setIsExpanded,
	};
};
