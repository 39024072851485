/**
 * @generated SignedSource<<7af220d702686c052bbe947164ada71a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type topTemplatePicker_projectsDirectoryV3$data = {
  readonly jira: {
    readonly canCreateProject: boolean | null | undefined;
    readonly projectListViewTemplates?: {
      readonly nodes: ReadonlyArray<{
        readonly productKey: string | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly userSegmentation: {
      readonly role: string | null | undefined;
      readonly teamType: string | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew">;
  } | null | undefined;
  readonly " $fragmentType": "topTemplatePicker_projectsDirectoryV3";
};
export type topTemplatePicker_projectsDirectoryV3$key = {
  readonly " $data"?: topTemplatePicker_projectsDirectoryV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"topTemplatePicker_projectsDirectoryV3">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v1 = {
  "kind": "Variable",
  "name": "experimentKey",
  "variableName": "experimentKey"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "experimentKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isAnonymous"
    }
  ],
  "kind": "Fragment",
  "name": "topTemplatePicker_projectsDirectoryV3",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "alias": "canCreateProject",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "type",
              "value": "CREATE_PROJECT"
            }
          ],
          "kind": "ScalarField",
          "name": "canPerform"
        },
        {
          "args": [
            (v0/*: any*/)
          ],
          "concreteType": "JiraUserSegmentation",
          "kind": "LinkedField",
          "name": "userSegmentation",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "teamType"
            },
            {
              "kind": "ScalarField",
              "name": "role"
            }
          ]
        },
        {
          "condition": "isAnonymous",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "args": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "concreteType": "JiraProjectListViewTemplateConnection",
              "kind": "LinkedField",
              "name": "projectListViewTemplates",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraProjectListViewTemplateItem",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "productKey"
                    }
                  ]
                }
              ]
            },
            {
              "args": [
                (v1/*: any*/)
              ],
              "kind": "FragmentSpread",
              "name": "topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "85558cc3212cf5c9baf79a2511c11b17";

export default node;
