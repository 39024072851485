import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

export { SearchControl } from './search-control';

type Props = {
	children: React.ReactElement;
};

export const RefinementBar = ({ children }: Props) => <Wrapper>{children}</Wrapper>;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	gap: token('space.200', '16px'),
	flex: '1',
	marginBottom: token('space.200', '16px'),
});
