/**
 * @generated SignedSource<<353fab3c4c3524d820a71cac36405614>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type actionsCell_projectsDirectoryV3_project$data = {
  readonly canEdit: {
    readonly canPerform: boolean;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"actionsCell_projectsDirectoryV3_ActionsCellDropdown_project">;
  readonly " $fragmentType": "actionsCell_projectsDirectoryV3_project";
};
export type actionsCell_projectsDirectoryV3_project$key = {
  readonly " $data"?: actionsCell_projectsDirectoryV3_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"actionsCell_projectsDirectoryV3_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "actionsCell_projectsDirectoryV3_project",
  "selections": [
    {
      "alias": "canEdit",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "EDIT_PROJECT_CONFIG"
        }
      ],
      "concreteType": "JiraProjectAction",
      "kind": "LinkedField",
      "name": "action",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "canPerform"
        }
      ],
      "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
    },
    {
      "kind": "FragmentSpread",
      "name": "actionsCell_projectsDirectoryV3_ActionsCellDropdown_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "9ab89008603b28efd3eba063cf807a1a";

export default node;
