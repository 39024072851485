import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { PerformanceMark } from '@atlassian/jira-common-performance';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import { LOAD_START_MARK_KEY } from './common/constants';
import type { Props } from './ui/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyComponent = lazy(
	() => import(/* webpackChunkName: "async-project-restore-modal" */ './index'),
);

const Component = (props: Props) => (
	<JSErrorBoundary
		fallback="flag"
		id="bundle-async-project-restore-modal"
		packageName="jira-project-restore-modal"
	>
		<Placeholder
			name="project-restore-modal"
			fallback={<PerformanceMark metricKey={LOAD_START_MARK_KEY} />}
		>
			<LazyComponent {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default Component;
