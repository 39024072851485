import React from 'react';
import {
	type ErrorBoundaryFlagProps,
	ErrorBoundaryFlag as CommonErrorBoundary,
} from '@atlassian/jira-error-boundary-flag-renderer';
import { SpaStateTransitionStateSubscriber } from '@atlassian/jira-spa-state-controller/src';

const ErrorBoundaryWithExtraEventData = ({ children, id }: ErrorBoundaryFlagProps) => (
	<CommonErrorBoundary id="spa-apps.error-boundary">
		<SpaStateTransitionStateSubscriber>
			{({ transitionsCount = 0, lastTransition = '', navigationStart = '' }) => {
				const extraEventData = {
					transitionsCount,
					lastTransition,
					navigationStart,
				};

				return (
					<CommonErrorBoundary id={id} extraEventData={extraEventData}>
						{children}
					</CommonErrorBoundary>
				);
			}}
		</SpaStateTransitionStateSubscriber>
	</CommonErrorBoundary>
);

const SpaErrorBoundary = ErrorBoundaryWithExtraEventData;

export default SpaErrorBoundary;
