import React from 'react';
import { styled } from '@compiled/react';
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled';

export const StarredHeader = ({ label }: { label: string }) => (
	<ContentWrapper>
		<StarFilledIcon size="small" label={label} />
	</ContentWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
});
