import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	starred: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-head.starred',
		defaultMessage: 'Starred',
		description: 'Assistive label for the Starred sorting button table header',
	},
	name: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-head.name',
		defaultMessage: 'Name',
		description: 'Name table header',
	},
	key: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-head.key',
		defaultMessage: 'Key',
		description: 'Key table header',
	},
	type: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-head.type',
		defaultMessage: 'Type',
		description: 'Type table header',
	},
	owner: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-head.owner',
		defaultMessage: 'Lead',
		description: 'Lead table header',
	},
	category: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-head.category',
		defaultMessage: 'Category',
		description: 'Category table header',
	},
	lastIssueUpdate: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-head.last-issue-update',
		defaultMessage: 'Last issue update',
		description: 'Last issue update table header',
	},
	projectUrl: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-head.project-url',
		defaultMessage: 'Project URL',
		description: 'Project URL table header',
	},
	action: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-head.action',
		defaultMessage: 'More actions',
		description: 'Column heading label for More actions column under Projects List Table',
	},
});
