import React, { useCallback } from 'react';
import { SearchControl as BaseSearchControl } from '@atlassian/jira-directory-base-v3';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { FilterFields } from '@atlassian/jira-projects-directory-v3-utils/src/constants';
import { useScopedFilter } from '../../../../controllers/filter-state';
import messages from './messages';

export const SearchControl = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'changed',
		actionSubject: 'refinementBar',
	});

	const [value, { updateFilter }] = useScopedFilter(FilterFields.Keyword);

	const onChange = useCallback(
		(newValue: string) => {
			updateFilter(FilterFields.Keyword, newValue);
			fireUIAnalytics(analyticsEvent, 'FilterSearch');
		},
		[analyticsEvent, updateFilter],
	);

	return (
		<BaseSearchControl
			name={FilterFields.Keyword}
			value={value}
			onChange={onChange}
			placeholderAlwaysVisible
			placeholder={formatMessage(messages.placeholder)}
		/>
	);
};
