import { setMark } from '@atlassian/jira-common-performance';

export const PRODUCT_START_MARK = 'PRODUCT_START';

export const MarkProductStart = () => {
	if (!__SERVER__) {
		performance.clearMarks(PRODUCT_START_MARK);
		setMark(PRODUCT_START_MARK);
	}
	return null;
};
