import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { DirectoryHeader } from '@atlassian/jira-directory-base-v3';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import type { header_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/header_projectsDirectoryV3.graphql';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller';
import { CreateButton } from './create-project-action';
import messages from './messages';
import { OpenTemplatesPanelButton } from './open-templates-panel';

interface Props {
	dataRef: header_projectsDirectoryV3$key;
	isAdminSettingsContext: boolean;
}

export const Header = ({ dataRef, isAdminSettingsContext }: Props) => {
	const { formatMessage } = useIntl();

	const { jira } = useFragment(
		graphql`
			fragment header_projectsDirectoryV3 on Query
			@argumentDefinitions(
				isProjectListSidebarExperimentEnabled: { type: "Boolean!" }
				isAnonymous: { type: "Boolean!" }
			) {
				jira {
					...createProjectAction_projectsDirectoryV3_CreateButton
					...openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton
						@skip(if: $isAnonymous)
						@include(if: $isProjectListSidebarExperimentEnabled)
				}
			}
		`,
		dataRef,
	);

	const isAnonymous = useIsAnonymous();

	return (
		<DirectoryHeader
			title={
				isAdminSettingsContext
					? formatMessage(messages.adminHeaderCaption)
					: formatMessage(messages.headerCaption)
			}
			actions={
				jira ? (
					<>
						<CreateButton dataRef={jira} />
						{!isAnonymous &&
							expValEquals(
								'contextual_bandits_reccs_in_projects_directory',
								'cohort',
								'variation',
							) && <OpenTemplatesPanelButton dataRef={jira} />}
					</>
				) : undefined
			}
			isAdminSettingsContext={isAdminSettingsContext}
		/>
	);
};
