import { useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import qs from 'query-string';
import { useRouter } from '@atlassian/jira-router';

interface Config {
	override?: boolean;
}

/**
 * syncs data with browser query string
 */
export const useQueryFilter = ({ override = true }: Config = {}) => {
	const [{ query, match }, routerActions] = useRouter();

	const syncQueryFilter = useCallback(
		(filter: object) => {
			const normalizedFilter = Object.entries(filter).reduce(
				(acc, [key, val]) => {
					if (val) acc[key] = val;
					return acc;
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{} as { [key: string]: string },
			);

			!isEqual(normalizedFilter, query) &&
				routerActions.replace(
					`${match.path}?${qs.stringify(
						override ? normalizedFilter : { ...query, ...normalizedFilter },
					)}`,
				);
		},
		[override, match.path, query, routerActions],
	);

	return syncQueryFilter;
};
