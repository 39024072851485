import React, { type ComponentPropsWithRef, forwardRef, type MutableRefObject } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import ArchiveTrigger from '@atlassian/jira-project-archive-trigger';

export const ArchiveProjectDropdownItem = forwardRef(
	(
		props: ComponentPropsWithRef<typeof ArchiveTrigger>,
		ref: MutableRefObject<HTMLElement | null> | ((instance: HTMLElement | null) => void) | null,
	) => (
		<ArchiveTrigger
			{...props}
			Component={({ children }) => (
				<DropdownItem ref={ref} onClick={props.onClick}>
					{children}
				</DropdownItem>
			)}
		/>
	),
);
