import React from 'react';
import { TableColumns } from '@atlassian/jira-projects-directory-v3-utils/src/constants';
import type { actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef$key } from '@atlassian/jira-relay/src/__generated__/actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef.graphql';
import type { actionsCell_projectsDirectoryV3_project$key } from '@atlassian/jira-relay/src/__generated__/actionsCell_projectsDirectoryV3_project.graphql';
import type { favouriteCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/favouriteCell_projectsDirectoryV3.graphql';
import type { keyCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/keyCell_projectsDirectoryV3.graphql';
import type { lastIssueUpdateCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/lastIssueUpdateCell_projectsDirectoryV3.graphql';
import type { leadCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/leadCell_projectsDirectoryV3.graphql';
import type { nameCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/nameCell_projectsDirectoryV3.graphql';
import type { projectCategoryCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/projectCategoryCell_projectsDirectoryV3.graphql';
import type { projectTypeCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/projectTypeCell_projectsDirectoryV3.graphql';
import type { urlCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/urlCell_projectsDirectoryV3.graphql';
import { ActionsCell } from './actions-cell';
import { FavouriteCell } from './favourite-cell';
import { KeyCell } from './key-cell';
import { LastIssueUpdateCell } from './last-issue-update-cell';
import { LeadCell } from './lead-cell';
import { NameCell } from './name-cell';
import { ProjectCategoryCell } from './project-category-cell';
import { ProjectTypeCell } from './project-type-cell';
import { UrlCell } from './url-cell';

export type projectRefType = favouriteCell_projectsDirectoryV3$key &
	keyCell_projectsDirectoryV3$key &
	nameCell_projectsDirectoryV3$key &
	projectTypeCell_projectsDirectoryV3$key &
	projectCategoryCell_projectsDirectoryV3$key &
	leadCell_projectsDirectoryV3$key &
	lastIssueUpdateCell_projectsDirectoryV3$key &
	urlCell_projectsDirectoryV3$key &
	actionsCell_projectsDirectoryV3_project$key;

export const getTableRow = (
	projectRef: projectRefType,
	isAdminSettingsContext: Boolean = false,
	jiraQueryRef: actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef$key | null,
) => ({
	cells: [
		{
			key: TableColumns.Favourites,
			content: <FavouriteCell project={projectRef} />,
		},
		{
			key: TableColumns.Name,
			content: <NameCell project={projectRef} />,
		},
		{
			key: TableColumns.Key,
			content: <KeyCell project={projectRef} />,
		},
		{
			key: TableColumns.Type,
			content: <ProjectTypeCell project={projectRef} />,
		},
		{
			key: TableColumns.Lead,
			content: <LeadCell project={projectRef} />,
		},
		{
			key: TableColumns.Category,
			content: <ProjectCategoryCell project={projectRef} />,
		},
		...(isAdminSettingsContext
			? [
					{
						key: TableColumns.LastIssueUpdate,
						content: <LastIssueUpdateCell project={projectRef} />,
					},
				]
			: []),
		{
			key: TableColumns.Url,
			content: <UrlCell project={projectRef} />,
		},
		{
			key: TableColumns.Action,
			content: <ActionsCell project={projectRef} jiraQueryRef={jiraQueryRef} />,
		},
	],
});
