// eslint-disable-next-line jira/wrm/no-load-bridge
import {
	waitForHeritage,
	loadBridgeWeak,
	loadBridgeStrict,
} from '@atlassian/jira-common-bridge/src';
import { JIRASERVICEMANAGEMENT } from '@atlassian/jira-common-constants/src/solutions-types';
import type { SubProduct } from '@atlassian/jira-common-constants/src/sub-product-types';
import log from '@atlassian/jira-common-util-logging/src/log';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';

const maybeDelayAndRace = (
	// @ts-expect-error - TS2304 - Cannot find name 'Deferred'.
	maybeWaitPromise: null | Deferred<undefined> | Promise<undefined>,
	getDefaultPromise: () => Promise<never>,
	getRacePromise: () => Promise<never>,
) =>
	maybeWaitPromise
		? Promise.race([maybeWaitPromise.then(getDefaultPromise), getRacePromise()])
		: getDefaultPromise();

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (subProduct: SubProduct, allowMonolithDeferral: boolean) => {
	getAnalyticsWebClientPromise().then((client) => {
		client.setSubProduct(subProduct);

		if (subProduct === 'serviceDesk') {
			client.setUIViewedAttributes({ solutions: [JIRASERVICEMANAGEMENT] });
		} else {
			client.removeUIViewedAttribute('solutions');
		}
	});

	// IMPORTANT -
	// Until such time as all pre-TTI WRM loads are removed we find that waitForHeritage() then
	// loadBridgeStrict() is too slow, resulting in `subproduct:unknown` for analytics events.
	// In that same case however, profiling shows us that polling in loadBridgeWeak() somehow
	// resolves the same AMD code faster.
	// This is not well understood, but it does allow us to work around the issue by racing these
	// two techniques.
	// Once there is no pre-TTI WRM loads we the waitForHeritage() then loadBridgeStrict() does seem
	// to be faster such that we can remove the `Promise.race()`.
	/* eslint-disable jira/wrm/no-load-bridge */
	maybeDelayAndRace(
		allowMonolithDeferral ? waitForHeritage() : null,
		() =>
			loadBridgeStrict({
				name: 'jira/analytics/sub-product-resolver',
				wrmKeys: ['wrc!com.atlassian.jira.jira-client-analytics-plugin:analytics-web-client'],
			}),
		() =>
			loadBridgeWeak({
				name: 'jira/analytics/sub-product-resolver',
			}),
	)
		.then((subProductResolver) => {
			subProductResolver.setSubProduct(subProduct);
		})
		.catch((e) =>
			log.safeErrorWithoutCustomerData(
				'common.analytics-sub-product.sub-product-updater',
				'Error connecting to monolith sub product resolver',
				e,
			),
		);
	/* eslint-enable jira/wrm/no-load-bridge */
};
