import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyNoPermissionViews = lazy(
	() =>
		import(/* webpackChunkName: "async-project-directory-no-permission-views" */ './index').then(
			({ NoPermissionViews }) => NoPermissionViews,
		),
	{ ssr: false },
);

export const AsyncNoPermissionViews = (props: Props) => (
	<Placeholder name="no-permission-views" fallback={null}>
		<LazyNoPermissionViews {...props} />
	</Placeholder>
);
