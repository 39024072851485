import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import {
	PANEL_ID,
	SIDEBAR_WIDTH,
	useSetProjectListRightPanelState,
} from '@atlassian/jira-growth-recommendations-in-project-list';
import { useIntl } from '@atlassian/jira-intl';
import {
	useActiveRightSidebarState,
	useRightSidebarController,
} from '@atlassian/jira-layout-controller';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	FireScreenAnalytics,
	SCREEN,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import type { openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton$key } from '@atlassian/jira-relay/src/__generated__/openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton.graphql';
import messages from './messages';

interface Props {
	dataRef: openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton$key;
}

const OpenTemplatesPanelButton = ({ dataRef }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'directoryHeader',
	});

	const rightSidebarCurrentState = useActiveRightSidebarState();
	const { openRightSidebar } = useRightSidebarController();
	const { formatMessage } = useIntl();
	const { canCreateProject, userPreferences, sidebarExperimentTemplates } =
		useFragment<openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton$key>(
			graphql`
				fragment openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton on JiraQuery {
					canCreateProject: canPerform(type: CREATE_PROJECT, cloudId: $cloudId)
						@optIn(to: "JiraAction")
					userPreferences(cloudId: $cloudId)
						@optIn(to: "JiraUserPreferences")
						@required(action: THROW) {
						__id
						projectListRightPanelState @optIn(to: "JiraProjectListRightPanelState")
					}
					sidebarExperimentTemplates: projectListViewTemplates(
						cloudId: $cloudId
						experimentKey: "sidebarExperiment"
					) @optIn(to: "JiraProjectListViewTemplate") @required(action: THROW) {
						totalCount @required(action: THROW)
					}
				}
			`,
			dataRef,
		);

	const setProjectListRightPanelState = useSetProjectListRightPanelState(userPreferences.__id);

	const onClick = useCallback(() => {
		fireUIAnalytics(analyticsEvent, 'openTemplatesPanelButton');

		if (
			userPreferences.projectListRightPanelState === 'OPEN' &&
			rightSidebarCurrentState?.panelId !== PANEL_ID
		) {
			openRightSidebar(PANEL_ID, SIDEBAR_WIDTH, false, true);
			return;
		}

		setProjectListRightPanelState(
			userPreferences.projectListRightPanelState === 'OPEN' ? 'CLOSED' : 'OPEN',
		);
	}, [
		analyticsEvent,
		openRightSidebar,
		rightSidebarCurrentState?.panelId,
		setProjectListRightPanelState,
		userPreferences.projectListRightPanelState,
	]);

	if (
		!canCreateProject ||
		typeof sidebarExperimentTemplates?.totalCount !== 'number' ||
		sidebarExperimentTemplates.totalCount === 0
	) {
		fireUIAnalytics(createAnalyticsEvent({}), 'openTemplatesPanelButton skipped', {
			reason: 'sidebarEligibility failed',
			canCreateProject,
			totalCount: sidebarExperimentTemplates.totalCount,
		});
		return null;
	}

	return (
		<>
			<Button isSelected={rightSidebarCurrentState?.panelId === PANEL_ID} onClick={onClick}>
				{formatMessage(messages.templatesButton)}
			</Button>
			<FireScreenAnalytics
				attributes={{
					experiment: 'recommendationsinProjectsDirectoryWithHoverPreview',
					totalCount: sidebarExperimentTemplates.totalCount,
					canCreateProject,
				}}
			/>
		</>
	);
};

const OpenTemplatesPanelButtonContainer = (props: Props) => (
	<JSErrorBoundary
		id="open-templates-panel-button"
		packageName="jiraProjectsDirectoryV3"
		fallback="unmount"
	>
		<ContextualAnalyticsData sourceName="projectsV3" sourceType={SCREEN}>
			<OpenTemplatesPanelButton {...props} />
		</ContextualAnalyticsData>
	</JSErrorBoundary>
);

export { OpenTemplatesPanelButtonContainer as OpenTemplatesPanelButton };
