import React from 'react';
import { styled as styled2 } from '@compiled/react';
import { JiraLogo, JiraProductDiscoveryLogo } from '@atlaskit/logo';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const productLogos: Record<string, React.ComponentType<any>> = {
	BUSINESS: JiraLogo,
	SOFTWARE: JiraLogo,
	PRODUCT_DISCOVERY: JiraProductDiscoveryLogo,
};

export const ProductBadge = ({ productKey }: { productKey: string | null | undefined }) => {
	if (!productKey) {
		return null;
	}

	const ProductLogo = productLogos[productKey];

	return ProductLogo ? (
		<ProductBadgeWrapper>
			<ProductLogo appearance="brand" />
		</ProductBadgeWrapper>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProductBadgeWrapper = styled2.div({
	height: '12px',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		height: '100%',
		display: 'flex',
		alignItems: 'center',

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& svg': {
			height: '100%',
			objectFit: 'contain',
		},
	},
});
