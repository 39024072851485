import {
	type ProjectType,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import {
	getEditionForProject,
	type ApplicationEditions,
	PREMIUM_EDITION,
	STANDARD_EDITION,
	FREE_EDITION,
} from '@atlassian/jira-shared-types';

export const hasPermissionGate = (
	projectType: ProjectType,
	appEditions: ApplicationEditions,
): boolean => getEditionForProject(projectType, appEditions) === PREMIUM_EDITION;

/*
 * Standard edition users will have access to the dropdown but no to archive projects
 */
export const isStandardEdition = (
	projectType: ProjectType,
	appEditions: ApplicationEditions,
): boolean => getEditionForProject(projectType, appEditions) === STANDARD_EDITION;

export const isFreeEdition = (
	projectType: ProjectType,
	appEditions: ApplicationEditions,
): boolean => getEditionForProject(projectType, appEditions) === FREE_EDITION;

const hasPermissionToArchiveProject = (
	isSimplified: boolean,
	isAdmin: boolean,
	canAdministerProject: boolean,
) => {
	if (isSimplified) {
		return isAdmin || canAdministerProject; // Either Jira admins or Project admins can archive the simplified/next-gen project
	}
	return isAdmin; // Only Jira admins can archive the classic software project
};

type Args = {
	projectType: ProjectType;
	isSimplified: boolean;
	isAdmin: boolean;
	canAdministerProject: boolean;
};

export const hasPermissionToArchive = ({
	projectType,
	isSimplified,
	isAdmin,
	canAdministerProject,
}: Args) => {
	switch (projectType) {
		case SOFTWARE_PROJECT:
		case SERVICE_DESK_PROJECT:
		case CORE_PROJECT:
			return hasPermissionToArchiveProject(isSimplified, isAdmin, canAdministerProject);
		default:
			return false;
	}
};
