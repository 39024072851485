import { createUseFilter } from '@atlassian/jira-directory-base-v3/src/controllers/create-use-filter';
import { matchAliases } from '@atlassian/jira-directory-base-v3/src/utils/search-string';
import {
	filterInitialState,
	filterAliasMap,
	type FilterInitialState,
} from '@atlassian/jira-projects-directory-v3-utils/src/constants';
import { sanitizeLegacyQuery } from '@atlassian/jira-projects-directory-v3-utils/src/utils';

const { useScopedFilter, useFilter, FilterContainer } = createUseFilter<FilterInitialState>(
	'ProjectsDirectoryFilterState',
	{
		getInitialState: ({ routeQuery }) =>
			matchAliases(filterInitialState, sanitizeLegacyQuery(routeQuery), filterAliasMap),
	},
);

export type UseScopedFilter = typeof useScopedFilter;
export { useScopedFilter, useFilter, FilterContainer };
