import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Select, { type OptionType, type OptionsType } from '@atlaskit/select';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { matchValueToSelectOptions } from '@atlassian/jira-directory-base-v3/src/utils/search-string';
import { useIntl } from '@atlassian/jira-intl';
import { getAriConfig } from '@atlassian/jira-platform-ari';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { FilterFields } from '@atlassian/jira-projects-directory-v3-utils/src/constants';
import type { projectCategoryPicker_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/projectCategoryPicker_projectsDirectoryV3.graphql';
import { useScopedFilter } from '../../../../controllers/filter-state';
import messages from './messages';

interface Props {
	dataRef: projectCategoryPicker_projectsDirectoryV3$key;
}

export const ProjectCategoryPicker = ({ dataRef }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'changed',
		actionSubject: 'refinementBar',
	});
	const [value, { updateFilter }] = useScopedFilter(FilterFields.ProjectCategoryId);

	const data = useFragment<projectCategoryPicker_projectsDirectoryV3$key>(
		graphql`
			fragment projectCategoryPicker_projectsDirectoryV3 on JiraQuery {
				allJiraProjectCategories(cloudId: $cloudId) {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		`,
		dataRef,
	);

	const onChange = useCallback(
		(newValue: OptionType | null) => {
			updateFilter({
				[FilterFields.ProjectCategoryId]: newValue ? String(newValue.value) : '',
				[FilterFields.ProjectCategoryLabel]: newValue ? String(newValue.label) : '',
			});
			fireUIAnalytics(analyticsEvent, 'ProjectCategoryPicker');
		},
		[updateFilter, analyticsEvent],
	);

	const options: OptionsType<OptionType> = useMemo(
		() =>
			data?.allJiraProjectCategories?.edges?.map((edge) => ({
				value: edge?.node?.id ? getAriConfig(edge?.node?.id).resourceId : '',
				label: edge?.node?.name || '',
			})) || [],
		[data],
	);

	const selectedValue = useMemo(() => matchValueToSelectOptions(value, options), [value, options]);

	return (
		<SelectWrapper>
			<Select
				placeholder={formatMessage(messages.placeholder)}
				isClearable
				name={FilterFields.ProjectCategoryId}
				value={selectedValue}
				onChange={onChange}
				options={options}
			/>
		</SelectWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize * 25}px`,
});
