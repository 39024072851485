import {
	type ProjectType,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import { getNextgenSettingsUrl } from '@atlassian/jira-project-settings-apps-common/src/urls';

const getClassicSettingsUrl = (projectType: ProjectType, projectKey: string) =>
	projectType === SERVICE_DESK_PROJECT
		? `/jira/servicedesk/projects/${projectKey}/settings`
		: `/projects/${projectKey}/settings`;

export const getSettingsUrl = (
	projectType: ProjectType,
	isSimplified: boolean,
	projectKey: string,
) =>
	!isSimplified || !projectType
		? getClassicSettingsUrl(projectType, projectKey)
		: getNextgenSettingsUrl(projectType, projectKey);
