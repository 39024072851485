import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { TopTemplatePickerNew } from '@atlassian/jira-project-list-template-picker';
import type { topTemplatePicker_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/topTemplatePicker_projectsDirectoryV3.graphql';

export interface Props {
	dataRef: topTemplatePicker_projectsDirectoryV3$key;
}

export const TopTemplatePicker = ({ dataRef }: Props) => {
	const { jira: data } = useFragment<topTemplatePicker_projectsDirectoryV3$key>(
		graphql`
			fragment topTemplatePicker_projectsDirectoryV3 on Query
			@argumentDefinitions(experimentKey: { type: "String" }, isAnonymous: { type: "Boolean!" }) {
				jira {
					canCreateProject: canPerform(type: CREATE_PROJECT, cloudId: $cloudId)
						@optIn(to: "JiraAction")
					userSegmentation(cloudId: $cloudId) @optIn(to: "JiraUserSegmentation") {
						teamType
						role
					}
					projectListViewTemplates(experimentKey: $experimentKey, cloudId: $cloudId)
						@optIn(to: "JiraProjectListViewTemplate")
						@skip(if: $isAnonymous) {
						nodes {
							productKey
						}
					}
					...topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew
						@arguments(experimentKey: $experimentKey)
						@skip(if: $isAnonymous)
				}
			}
		`,
		dataRef,
	);

	const isJpdRecommended = useMemo(
		() =>
			data?.projectListViewTemplates?.nodes?.some(
				(template) => template?.productKey === 'PRODUCT_DISCOVERY',
			),
		[data?.projectListViewTemplates?.nodes],
	);

	const attributes = useMemo(
		() => ({
			role: data?.userSegmentation?.role ?? '',
			teamType: data?.userSegmentation?.teamType,
			isJpdRecommended,
		}),
		[data?.userSegmentation?.role, data?.userSegmentation?.teamType, isJpdRecommended],
	);

	const isJpdTemplateIncluded =
		// eslint-disable-next-line jira/ff/no-preconditioning
		expValEquals('productionise_jpd_recommendations_in_project_list', 'cohort', 'variation') &&
		data?.canCreateProject &&
		isJpdRecommended;

	if (data && (data?.canCreateProject || isJpdTemplateIncluded)) {
		return <TopTemplatePickerNew dataRef={data} attributes={attributes} />;
	}

	if (data && data?.canCreateProject) return <TopTemplatePickerNew dataRef={data} />;

	return null;
};
