import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import CommonLink from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link';
import { CellWrapper } from '@atlassian/jira-directory-base-v3/src/ui/table-cells/styled';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	nameCell_projectsDirectoryV3$key,
	nameCell_projectsDirectoryV3$data,
	JiraProjectStyle,
} from '@atlassian/jira-relay/src/__generated__/nameCell_projectsDirectoryV3.graphql';

interface Props {
	project: nameCell_projectsDirectoryV3$key;
}

type AvatarSize = '16px' | '24px' | '32px' | '48px' | '64px' | '72px' | '96px' | '128px';

// Get the landing URL directly for JSW projects
export const getProjectLandingUrl = (
	key: string | undefined,
	navigationMetadata: nameCell_projectsDirectoryV3$data['navigationMetadata'],
	projectStyle: JiraProjectStyle | undefined | null,
) => {
	if (!navigationMetadata) {
		return `/browse/${key}`;
	}
	if (navigationMetadata.__typename === 'JiraSoftwareProjectNavigationMetadata') {
		if (projectStyle === 'TEAM_MANAGED_PROJECT') {
			return `/jira/software/projects/${key}/boards/${navigationMetadata.boardId}`;
		}

		if (projectStyle === 'COMPANY_MANAGED_PROJECT') {
			return `/jira/software/c/projects/${key}/boards/${navigationMetadata.boardId}`;
		}
	}

	if (navigationMetadata.__typename === 'JiraServiceManagementProjectNavigationMetadata') {
		return fg('fix_jsm_queue_redirect_issue_from_project_list')
			? `/jira/servicedesk/projects/${key}`
			: `/jira/servicedesk/projects/${key}/queues/custom/${navigationMetadata.queueId}`;
	}

	return `/browse/${key}`;
};

export const NameCell = ({ project }: Props) => {
	const data = useFragment(
		graphql`
			fragment nameCell_projectsDirectoryV3 on JiraProject {
				name
				key
				avatar {
					medium
				}
				projectStyle
				navigationMetadata {
					__typename
					... on JiraSoftwareProjectNavigationMetadata {
						boardId
					}
					... on JiraServiceManagementProjectNavigationMetadata {
						queueId
					}
				}
			}
		`,
		project,
	);

	const boardUrl = getProjectLandingUrl(data?.key, data?.navigationMetadata, data?.projectStyle);

	if (data?.avatar?.medium) {
		return (
			<Container>
				<Link href={boardUrl}>
					<Box xcss={AvatarStyles} as="img" src={data.avatar.medium} alt="" />
					<WithAvatarLabel>{data.name}</WithAvatarLabel>
				</Link>
			</Container>
		);
	}
	return (
		<Container>
			<Link href={boardUrl}>
				<NoAvatarLabel>{data.name}</NoAvatarLabel>
			</Link>
		</Container>
	);
};

export const avatarSizePx: AvatarSize = '24px';

// When we need to display a label for a project that has no avatar, we need to ensure that the label is aligned
// with other labels that have avatars. To do this we need to have a larger left margin to account for the avatar
// (24px / space.300) and the gap (8px / space.100) between the avatar and the label.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoAvatarLabel = styled.span({
	marginLeft: token('space.400', '32px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// When we need to display a label for a project that has an avatar, we need to just ensure that there is a gap
// between the avatar and the label. This is why we set the left margin to 8px / space.100.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WithAvatarLabel = styled.span({
	marginLeft: token('space.100', '8px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const AvatarStyles = xcss({
	flexShrink: 0,
	width: token('space.300', '24px'),
	height: token('space.300', '24px'),
	borderRadius: 'border.radius.050',
	display: 'inline-block',
	verticalAlign: 'middle',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Link = styled(CommonLink)({
	display: 'block',
	overflowX: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled(CellWrapper)({
	alignItems: 'center',
	overflow: 'unset',
});
