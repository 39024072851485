import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { TableCell, type CellType } from './table-cell';

export type RowType = {
	cells: CellType[];
	columnKeys: string[];
};

export const TableRow = ({ columnKeys, cells }: RowType) => {
	const Cells = useMemo(
		() =>
			cells
				.filter((cell) => columnKeys.includes(cell.key))
				.map(({ key, content }) => <TableCell key={`table-row-cell-${key}`}>{content}</TableCell>),
		[columnKeys, cells],
	);
	return <TableRowContainer>{Cells}</TableRowContainer>;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableRowContainer = styled.tr({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 6}px`,
	position: 'relative',
	borderWidth: 'initial',
	borderStyle: 'none',
	borderColor: 'initial',
	width: '100%',

	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.hovered', colors.N20A),
	},
});
