/**
 * @generated SignedSource<<e53995b271f2cf83fef198bdd0e5c27c>>
 * @relayHash ff03d251842daa1f5df6e0e396299d48
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 32cfd611a359c362eab84d2c6f1342893f7abf65a4f395b2628de7e248eb3e0b

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraProjectNotificationConfigurationState = "CONFIGURED" | "DEFAULT" | "%future added value";
export type JiraProjectSortField = "CATEGORY" | "FAVOURITE" | "KEY" | "LAST_ISSUE_UPDATED_TIME" | "LEAD" | "NAME" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type JiraProjectFilterInput = {
  keyword?: string | null | undefined;
  notificationConfigurationState?: JiraProjectNotificationConfigurationState | null | undefined;
  projectCategoryId?: string | null | undefined;
  sortBy?: JiraProjectSortInput | null | undefined;
  types?: ReadonlyArray<JiraProjectType> | null | undefined;
};
export type JiraProjectSortInput = {
  order?: SortDirection | null | undefined;
  sortBy?: JiraProjectSortField | null | undefined;
};
export type ProjectDirectoryRefetchQuery$variables = {
  cloudId: string;
  filter: JiraProjectFilterInput;
  first?: number | null | undefined;
  isAdminSettingsContext: boolean;
  last?: number | null | undefined;
};
export type ProjectDirectoryRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"pageLayout_projectsDirectoryV3_ProjectDirectoryLayout">;
};
export type ProjectDirectoryRefetchQuery = {
  response: ProjectDirectoryRefetchQuery$data;
  variables: ProjectDirectoryRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isAdminSettingsContext"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "kind": "ScalarField",
  "name": "isFavourite"
},
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ProjectDirectoryRefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "isAdminSettingsContext",
            "variableName": "isAdminSettingsContext"
          }
        ],
        "kind": "FragmentSpread",
        "name": "pageLayout_projectsDirectoryV3_ProjectDirectoryLayout"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectDirectoryRefetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
              },
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "last",
                "variableName": "last"
              }
            ],
            "concreteType": "JiraProjectConnection",
            "kind": "LinkedField",
            "name": "allJiraProjects",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "canViewIssue",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "type",
                            "value": "VIEW_ISSUES"
                          }
                        ],
                        "concreteType": "JiraProjectAction",
                        "kind": "LinkedField",
                        "name": "action",
                        "plural": false,
                        "selections": (v1/*: any*/),
                        "storageKey": "action(type:\"VIEW_ISSUES\")"
                      },
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "concreteType": "JiraFavouriteValue",
                        "kind": "LinkedField",
                        "name": "favouriteValue",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "__id"
                              }
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "key"
                      },
                      {
                        "concreteType": "JiraAvatar",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "medium"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "projectStyle"
                      },
                      {
                        "kind": "LinkedField",
                        "name": "navigationMetadata",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "boardId"
                              },
                              (v2/*: any*/)
                            ],
                            "type": "JiraSoftwareProjectNavigationMetadata"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "queueId"
                              }
                            ],
                            "type": "JiraServiceManagementProjectNavigationMetadata"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "projectType"
                      },
                      {
                        "concreteType": "JiraProjectCategory",
                        "kind": "LinkedField",
                        "name": "category",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "lead",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isUser"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "accountId"
                          },
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "picture"
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "condition": "isAdminSettingsContext",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": "lastUpdatedToolTipDate",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "format",
                                "value": "COMPLETE_DATETIME_FORMAT"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "lastUpdatedFormatted",
                            "storageKey": "lastUpdatedFormatted(format:\"COMPLETE_DATETIME_FORMAT\")"
                          },
                          {
                            "alias": "lastUpdatedDisplayDate",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "format",
                                "value": "RELATIVE"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "lastUpdatedFormatted",
                            "storageKey": "lastUpdatedFormatted(format:\"RELATIVE\")"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "projectUrl"
                      },
                      {
                        "alias": "canEdit",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "type",
                            "value": "EDIT_PROJECT_CONFIG"
                          }
                        ],
                        "concreteType": "JiraProjectAction",
                        "kind": "LinkedField",
                        "name": "action",
                        "plural": false,
                        "selections": (v1/*: any*/),
                        "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
                      },
                      {
                        "alias": "jiraProjectType",
                        "kind": "ScalarField",
                        "name": "projectType"
                      },
                      {
                        "alias": "jiraProjectName",
                        "kind": "ScalarField",
                        "name": "name"
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "totalCount"
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isHasTotal"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "32cfd611a359c362eab84d2c6f1342893f7abf65a4f395b2628de7e248eb3e0b",
    "metadata": {},
    "name": "ProjectDirectoryRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "eb5983a7159e6add64de193b1e14a2b6";

export default node;
