/**
 * @generated SignedSource<<3830e2db04f3d6dcedd8e32cc486466c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type templateGallery_projectListTemplatePicker_TemplateGalleryNew$data = {
  readonly projectListViewTemplates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly iconUrl: AGG$URL | null | undefined;
        readonly isLastUsed: boolean | null | undefined;
        readonly isPremiumOnly: boolean | null | undefined;
        readonly isProductLicensed: boolean | null | undefined;
        readonly key: string | null | undefined;
        readonly productKey: string | null | undefined;
        readonly title: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "templateGallery_projectListTemplatePicker_TemplateGalleryNew";
};
export type templateGallery_projectListTemplatePicker_TemplateGalleryNew$key = {
  readonly " $data"?: templateGallery_projectListTemplatePicker_TemplateGalleryNew$data;
  readonly " $fragmentSpreads": FragmentRefs<"templateGallery_projectListTemplatePicker_TemplateGalleryNew">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "experimentKey"
    }
  ],
  "kind": "Fragment",
  "name": "templateGallery_projectListTemplatePicker_TemplateGalleryNew",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        },
        {
          "kind": "Variable",
          "name": "experimentKey",
          "variableName": "experimentKey"
        }
      ],
      "concreteType": "JiraProjectListViewTemplateConnection",
      "kind": "LinkedField",
      "name": "projectListViewTemplates",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraProjectListViewTemplateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraProjectListViewTemplateItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "key"
                },
                {
                  "kind": "ScalarField",
                  "name": "title"
                },
                {
                  "kind": "ScalarField",
                  "name": "isLastUsed"
                },
                {
                  "kind": "ScalarField",
                  "name": "isPremiumOnly"
                },
                {
                  "kind": "ScalarField",
                  "name": "iconUrl"
                },
                {
                  "kind": "ScalarField",
                  "name": "productKey"
                },
                {
                  "kind": "ScalarField",
                  "name": "isProductLicensed"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "8058cf06299bfa0332df30fe02af6a66";

export default node;
