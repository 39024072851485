import { useRef, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import type { Options } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useDebouncedCallback<F extends (...rest: ReadonlyArray<any>) => any>(
	callback: F,
	delay: number,
	options?: Options,
) {
	const optionsRef = useRef(options);
	// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
	const callbackRef = useRef((...args) => {
		callback(...args);
	});
	callbackRef.current = callback;
	const debouncedCallBack = useMemo(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		() => debounce((...args: any[]) => callbackRef.current(...args), delay, optionsRef.current),
		[delay],
	);
	useEffect(() => debouncedCallBack.cancel, [debouncedCallBack]);

	return [debouncedCallBack, debouncedCallBack.cancel] as const;
}
