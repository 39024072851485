import React, { useCallback, useMemo, useEffect } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import ArrowRightIconLegacy from '@atlaskit/icon/glyph/arrow-right';
import Lozenge from '@atlaskit/lozenge';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import {
	type AnalyticsAttributes,
	fireUIAnalytics,
	useAnalyticsEvents,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { ProjectCreateDrawerConsumer } from '@atlassian/jira-project-create-drawer-context';
import type { OpenDrawerMethod } from '@atlassian/jira-project-create-drawer-context/src/types';
import type { templateGallery_projectListTemplatePicker_TemplateGalleryNew$key } from '@atlassian/jira-relay/src/__generated__/templateGallery_projectListTemplatePicker_TemplateGalleryNew.graphql';
import { PACKAGE_NAME } from '../../../constants';
import messages from './messages';
import { ProductBadge } from './product-badge';
import type { CardClickAnalytics } from './types';

export const SOURCE = 'projectListTemplatePicker';
export const CARD_CONTAINER_TEST_ID =
	'project-list-template-picker.ui.top-template-picker.template-gallery.card-container';
export const CARD_TEST_ID =
	'project-list-template-picker.ui.top-template-picker.template-gallery.card';
export const VIEW_MORE_CARD_TEST_ID =
	'project-list-template-picker.ui.top-template-picker.template-gallery.view-more-card';

type NewProps = {
	isOpen: boolean;
	dataRef: templateGallery_projectListTemplatePicker_TemplateGalleryNew$key;
	attributes?: AnalyticsAttributes;
};

const incorrectTemplatesMessage = 'Correct templates not being shown - 0 or 1 templates shown';
const incorrectTemplatesError = new Error(incorrectTemplatesMessage);

export const TemplateGalleryNew = ({ isOpen, dataRef, attributes }: NewProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { projectListViewTemplates } =
		useFragment<templateGallery_projectListTemplatePicker_TemplateGalleryNew$key>(
			graphql`
				fragment templateGallery_projectListTemplatePicker_TemplateGalleryNew on JiraQuery
				@argumentDefinitions(experimentKey: { type: "String" }) {
					projectListViewTemplates(experimentKey: $experimentKey, cloudId: $cloudId)
						@optIn(to: "JiraProjectListViewTemplate") {
						edges {
							node {
								key
								title
								isLastUsed
								isPremiumOnly
								iconUrl
								productKey
								isProductLicensed
							}
						}
					}
				}
			`,
			dataRef,
		);

	const templates = useMemo(
		() => projectListViewTemplates?.edges?.map((edge) => edge?.node),
		[projectListViewTemplates?.edges],
	);

	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'topTemplatePickerTemplates viewed', {
			numberOfTemplates: templates?.length,
			shownTemplates: templates?.map((t) => t?.key),
			isJpdLicensed:
				templates?.find((t) => t?.productKey === 'PRODUCT_DISCOVERY')?.isProductLicensed ??
				undefined,
		});

		// GROW-889: Remove fireErrorAnalytics code once we stop receiving these errors
		if (!templates || templates?.length <= 1) {
			fireErrorAnalytics({
				meta: {
					id: 'templateGalleryNew',
					packageName: PACKAGE_NAME,
					teamName: 'jira-warepil',
				},
				attributes: {
					message: incorrectTemplatesMessage,
					numberOfTemplates: templates?.length,
					...attributes,
				},
				error: incorrectTemplatesError,
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}, [attributes, createAnalyticsEvent, templates]);

	const onCardClick = useCallback(
		(
			open: OpenDrawerMethod,
			{
				selectedTemplateKey,
				selectedTemplatePosition,
				numberOfTemplates,
				templateProductKey,
			}: CardClickAnalytics,
		) => {
			open({
				selectedProjectType: 'business',
				showExperienceSelection: false,
				defaultSelectedTemplateKey: selectedTemplateKey,
				source: SOURCE,
			});

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});
			fireUIAnalytics(
				analyticsEvent,
				selectedTemplateKey ? 'projectListTemplatePicker' : 'projectListTemplatePickerViewAll',
				{
					selectedTemplateKey,
					selectedTemplatePosition,
					numberOfTemplates,
					templateProductKey,
					source: SOURCE,
				},
			);
		},
		[createAnalyticsEvent],
	);

	return (
		<ProjectCreateDrawerConsumer>
			{({ methods: { open } }) => (
				<CardContainerNew isOpen={isOpen} data-testid={CARD_CONTAINER_TEST_ID}>
					{templates?.map((template, index) => (
						<Card
							data-testid={CARD_TEST_ID}
							key={template?.key}
							onClick={() => {
								onCardClick(open, {
									selectedTemplateKey: template?.key || undefined,
									numberOfTemplates: templates.length,
									selectedTemplatePosition: index,
									templateProductKey: template?.productKey || undefined,
								});
							}}
						>
							<TemplateContent>
								{template?.isLastUsed && (
									<Lozenge isBold>{formatMessage(messages.lastCreated)}</Lozenge>
								)}
								{!template?.isLastUsed && template?.isPremiumOnly && (
									<Lozenge appearance="new" isBold>
										{formatMessage(messages.premium)}
									</Lozenge>
								)}
								{/* eslint-disable-next-line jira/ff/no-preconditioning */}
								{expValEquals(
									'productionise_jpd_recommendations_in_project_list',
									'cohort',
									'variation',
								) &&
									!template?.isLastUsed &&
									!template?.isPremiumOnly &&
									!template?.isProductLicensed && (
										<Lozenge appearance="new">{formatMessage(messages.try)}</Lozenge>
									)}
								<TemplateIcon iconUrl={template?.iconUrl || ''} />
							</TemplateContent>
							<TemplateTitle>{template?.title}</TemplateTitle>
							<ProductBadge productKey={template?.productKey} />
						</Card>
					))}
					<ViewMoreCard
						data-testid={VIEW_MORE_CARD_TEST_ID}
						onClick={() => {
							onCardClick(open, {
								numberOfTemplates: templates?.length,
							});
						}}
					>
						<TemplateContent>
							<Circle>
								<ArrowRightIcon
									label=""
									LEGACY_size="medium"
									LEGACY_fallbackIcon={ArrowRightIconLegacy}
								/>
							</Circle>
						</TemplateContent>
						<ViewAllTemplatesTitle>
							{formatMessage(messages.viewAllTemplates)}
						</ViewAllTemplatesTitle>
					</ViewMoreCard>
				</CardContainerNew>
			)}
		</ProjectCreateDrawerConsumer>
	);
};

const ICON_WIDTH = 105;
const ICON_HEIGHT = 70;
const CIRCLE_RADIUS = 28;

const TOTAL_HEIGHT = 168;
export const TRANSITION_CLASS_NAME = 'template-picker-card-container';
export const ANIMATION_DURATION = 300;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateContent = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	flex: 1,
});

const closedStylesCompiled = {
	maxHeight: 0,
	paddingTop: 0,
	paddingBottom: 0,
	marginBottom: 0,
};

const openedStylesCompiled = {
	maxHeight: `${TOTAL_HEIGHT}px`,
	paddingTop: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),
	marginBottom: token('space.200', '16px'),
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardContainerNew = styled.div<{ isOpen: boolean }>({
	display: 'flex',
	flexDirection: 'row',
	overflowX: 'auto',
	overflowY: 'hidden',
	/* Padding so that the box-shadow is not being cut */
	paddingLeft: token('space.025', '2px'),
	paddingRight: token('space.025', '2px'),
	transition: `all ease-in-out ${ANIMATION_DURATION}ms`,
	transitionProperty: 'max-height, margin, padding',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ isOpen }) => (isOpen ? { ...openedStylesCompiled } : { ...closedStylesCompiled }),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Card = styled.button({
	border: 'none',
	boxSizing: 'unset',
	maxWidth: '129px',
	backgroundColor: token('elevation.surface.sunken', '#FFFFFF'),
	boxShadow: token(
		'elevation.shadow.raised',
		'0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px 1px rgba(9, 30, 66, 0.13)',
	),
	cursor: 'pointer',
	borderRadius: '8px',
	display: 'inline-flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'space-between',
	height: '140px',
	padding: token('space.200', '16px'),
	flex: 1,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& + &': {
		marginLeft: token('space.100', '8px'),
	},

	'&:hover': {
		backgroundColor: token('color.background.neutral.hovered', '#091E4224'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateIcon = styled.div<{ iconUrl: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundImage: ({ iconUrl }) => `url(${iconUrl})`,
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	width: `${ICON_WIDTH}px`,
	height: `${ICON_HEIGHT}px`,
	marginTop: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateTitle = styled.p({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.heading.xxsmall', fontFallback.heading.xxsmall),
	color: token('color.text', '#172B4D'),
	textAlign: 'center',
	marginTop: 0,
	height: token('space.400', '32px'),
	display: 'inline-flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewMoreCard = styled(Card)({
	backgroundColor: token('color.background.neutral', '#FAFBFC'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Circle = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: `${ICON_WIDTH}px`,
	height: `${ICON_HEIGHT}px`,
	position: 'relative',

	'&::before': {
		content: '',
		width: `${CIRCLE_RADIUS * 2}px`,
		height: `${CIRCLE_RADIUS * 2}px`,
		borderRadius: `${CIRCLE_RADIUS}px`,
		backgroundColor: token('color.background.neutral.hovered', 'rgba(9, 30, 66, 0.04)'),
		position: 'absolute',

		'&:hover': {
			backgroundColor: token('color.background.neutral.subtle.pressed', 'rgba(9, 30, 66, 0.04)'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewAllTemplatesTitle = styled(TemplateTitle)({
	color: token('color.text.subtle', '#44546F'),
	height: token('space.400', '32px'),
});
