import React from 'react';
import { styled } from '@compiled/react';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { useDebouncedCallback } from '@atlassian/jira-platform-use-debounce';
import { SearchField } from '@atlassian/jira-searchfield';

type SearchControlProps = {
	name: string;
	value: string;
	onChange: (value: string) => void;
	placeholderAlwaysVisible?: boolean;
	placeholder?: string;
};

export const SearchControl = ({
	name,
	value,
	onChange,
	placeholder,
	placeholderAlwaysVisible,
}: SearchControlProps) => {
	const [onInputChange] = useDebouncedCallback((newValue) => {
		onChange(newValue);
	}, 500);

	return (
		<SearchControlWrapper>
			<SearchField
				isCompact
				id={name}
				shouldFitContainerWidth
				value={value}
				onChange={onInputChange}
				isAutocompleteDisabled
				placeholderAlwaysVisible={placeholderAlwaysVisible}
				placeholder={placeholder}
			/>
		</SearchControlWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SearchControlWrapper = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize * 28}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 28}px`,
});
