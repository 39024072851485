import { isLoaderError } from 'react-loosely-lazy';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';

/**
 * Return a new {@link AnalyticsAttributes} object which checks if the provided error is a RLL chunk load error. This is
 * suitable to be set as the `attributes` prop of a `<ReportErrors /> component.
 */
export const isLoaderErrorAttributes = (error: Error): AnalyticsAttributes => ({
	isLoaderError: isLoaderError(error),
});
