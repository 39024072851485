import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller';
import messages from './messages';
import type { Props } from './types';
import {
	hasPermissionGate,
	hasPermissionToArchive,
	isFreeEdition,
	isStandardEdition,
} from './utils';

const ProjectArchiveTrigger = (props: Props) => {
	const {
		projectType,
		onClick,
		isAdmin = false,
		canAdministerProject = false,
		isSimplified = false,
		Component,
		isCorePremiumUserSeat = false,
		shouldHideFeatureGateTrigger = false,
		customLabelText,
	} = props;

	const { formatMessage } = useIntl();

	const premiumLozenge = useMemo(
		() => (
			<LozengeWrapper>
				<Lozenge appearance="new">{formatMessage(messages.premiumLozenge)}</Lozenge>
			</LozengeWrapper>
		),
		[formatMessage],
	);

	const getDropDownItem = useCallback(
		(showPremiumLozenge = false) =>
			Component ? (
				<Component>
					{customLabelText ||
						formatMessage(
							projectType === SERVICE_DESK_PROJECT
								? messages.archiveTriggerServiceManagement
								: messages.archiveTrigger,
						)}
					{showPremiumLozenge && premiumLozenge}
				</Component>
			) : (
				<DropdownItem onClick={onClick}>
					{customLabelText ||
						formatMessage(
							projectType === SERVICE_DESK_PROJECT
								? messages.archiveTriggerServiceManagement
								: messages.archiveTrigger,
						)}
					{showPremiumLozenge && premiumLozenge}
				</DropdownItem>
			),
		[Component, formatMessage, onClick, projectType, premiumLozenge, customLabelText],
	);

	if (!hasPermissionToArchive({ projectType, isSimplified, isAdmin, canAdministerProject })) {
		return null;
	}

	return (
		<TenantContextSubscriber>
			{({ tenantContext: { appEditions } }) => {
				const isSoftwareAppPremiumEdition = hasPermissionGate(SOFTWARE_PROJECT, appEditions);
				const isServiceDeskAppPremiumEdition = hasPermissionGate(SERVICE_DESK_PROJECT, appEditions);
				const isSoftwareAppStandardEdition = isStandardEdition(SOFTWARE_PROJECT, appEditions);

				const isSoftwareAppFreeEdition = isFreeEdition(SOFTWARE_PROJECT, appEditions);

				if (projectType === SOFTWARE_PROJECT && isSoftwareAppPremiumEdition) {
					return getDropDownItem();
				}

				if (projectType === SERVICE_DESK_PROJECT && isServiceDeskAppPremiumEdition) {
					return getDropDownItem();
				}

				// Enable Archive feature gate for admins in standard edition for JSW projects
				if (
					ff('arj-project-archiving-feature-gate_z01zt') &&
					isAdmin &&
					projectType === SOFTWARE_PROJECT &&
					isSoftwareAppStandardEdition &&
					!shouldHideFeatureGateTrigger
				) {
					return getDropDownItem(true);
				}

				// Enable Archive feature gate for admins in standard edition for Core projects
				if (
					ff('arj-project-archiving-feature-gate_z01zt') &&
					isAdmin &&
					projectType === CORE_PROJECT &&
					isSoftwareAppStandardEdition &&
					!shouldHideFeatureGateTrigger
				) {
					return getDropDownItem(true);
				}

				// Enable Archive feature gate for admins in free edition for JSW projects
				if (
					isAdmin &&
					projectType === SOFTWARE_PROJECT &&
					isSoftwareAppFreeEdition &&
					!shouldHideFeatureGateTrigger &&
					fg('project_archive_feature_gate_in_free_instances')
				) {
					return getDropDownItem(true);
				}

				// Enable Archive feature gate for admins in free edition for Core projects
				if (
					isAdmin &&
					projectType === CORE_PROJECT &&
					isSoftwareAppFreeEdition &&
					!shouldHideFeatureGateTrigger &&
					fg('project_archive_feature_gate_in_free_instances')
				) {
					return getDropDownItem(true);
				}

				// Enable Archive Feature for Core projects if either Software, Service Desk or Core are Premium
				if (projectType === CORE_PROJECT && isCorePremiumUserSeat) {
					return getDropDownItem();
				}

				return null;
			}}
		</TenantContextSubscriber>
	);
};

export default ProjectArchiveTrigger;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.div({
	display: 'inline',
	marginLeft: token('space.050', '4px'),
});
