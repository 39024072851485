import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	recommendedTemplatesTitle: {
		id: 'project-list-template-picker.top-template-picker.recommended-templates-title',
		defaultMessage: 'Recommended templates for teams like yours',
		description: 'Title of template gallery that shows different templates for project creation',
	},
	showGallery: {
		id: 'project-list-template-picker.top-template-picker.show-gallery',
		defaultMessage: 'Show gallery',
		description: 'Button content to show template gallery',
	},
	hideGallery: {
		id: 'project-list-template-picker.top-template-picker.hide-gallery',
		defaultMessage: 'Hide gallery',
		description: 'Button content to hide template gallery',
	},
});
