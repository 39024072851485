import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import {
	graphql,
	usePreloadedQuery,
	useRefetchableFragment,
	type PreloadedQuery,
} from 'react-relay';
import { AnnouncerV2 } from '@atlassian/jira-accessibility';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { useDirectoryRefetch } from '@atlassian/jira-directory-base-v3/src/controllers/use-directory-refetch';
import { useQueryFilter } from '@atlassian/jira-directory-base-v3/src/controllers/use-query-filter';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import {
	useExperienceFail,
	ExperienceSuccess as PlatformExperienceSuccess,
} from '@atlassian/jira-experience-tracker';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { DocumentTitle } from '@atlassian/jira-global-document-title';
import { useIntl } from '@atlassian/jira-intl';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous';
import {
	PROJECTS_DIRECTORY_EXPERIENCE,
	MANAGE_PROJECTS_DIRECTORY_EXPERIENCE,
	DEFAULT_LIMIT,
	FilterFields,
	type FilterInitialState as FilterState,
} from '@atlassian/jira-projects-directory-v3-utils/src/constants';
import { mapQueryToFilter } from '@atlassian/jira-projects-directory-v3-utils/src/utils';
import { pageLoad } from '@atlassian/jira-projects-directory-v3-utils/src/utils/performance-analytics';
import { SubmitApdex } from '@atlassian/jira-providers-spa-apdex-analytics/src';
import type { pageLayout_projectsDirectoryLayout_RootQuery } from '@atlassian/jira-relay/src/__generated__/pageLayout_projectsDirectoryLayout_RootQuery.graphql';
import type { pageLayout_projectsDirectoryV3_ProjectDirectoryLayout$key } from '@atlassian/jira-relay/src/__generated__/pageLayout_projectsDirectoryV3_ProjectDirectoryLayout.graphql';
import RefetchProjectsQuery, {
	type ProjectDirectoryRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/ProjectDirectoryRefetchQuery.graphql';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product';
import { ScrollablePage } from '@atlassian/jira-spa-apps-page-layout';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller';
import {
	useCloudId,
	useIsAdmin,
	useIsSiteAdmin,
	useIsAnonymous,
} from '@atlassian/jira-tenant-context-controller';
import { FilterContainer } from '../../controllers/filter-state';
import { AsyncEmptyView } from './empty-view/async';
import { PageErrorState } from './error-state';
import { Header } from './header';
import messages from './messages';
import { AsyncNoPermissionViews } from './no-permission-views/async';
import { ProjectsList } from './projects-list';
import { RefinementBar } from './refinement-bar';
import { ShouldShowRightSidebar } from './right-panel';
import { TopTemplatePicker } from './top-template-picker';

export { PageErrorState } from './error-state';

interface Props {
	queryRef: PreloadedQuery<pageLayout_projectsDirectoryLayout_RootQuery>;
	isAdminSettingsContext: boolean;
}

export const ProjectDirectoryLayout = ({ queryRef, isAdminSettingsContext }: Props) => {
	const experienceKey = isAdminSettingsContext
		? MANAGE_PROJECTS_DIRECTORY_EXPERIENCE
		: PROJECTS_DIRECTORY_EXPERIENCE;
	const cloudId = useCloudId();
	const syncQueryFilter = useQueryFilter();
	const onFail = useExperienceFail({
		experience: experienceKey,
	});
	const { formatMessage } = useIntl();

	/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
	const rootQueryRef = usePreloadedQuery<pageLayout_projectsDirectoryLayout_RootQuery>(
		graphql`
			query pageLayout_projectsDirectoryLayout_RootQuery(
				$cloudId: ID!
				$filter: JiraProjectFilterInput!
				$first: Int
				$last: Int
				$isAdminSettingsContext: Boolean!
				$experimentKey: String
				$isAnonymous: Boolean!
				$isProjectListSidebarExperimentEnabled: Boolean!
				$skipShouldShowRightSidebar: Boolean!
			) @preloadable {
				...header_projectsDirectoryV3
					@arguments(
						isProjectListSidebarExperimentEnabled: $isProjectListSidebarExperimentEnabled
						isAnonymous: $isAnonymous
					)
				...topTemplatePicker_projectsDirectoryV3
					@arguments(experimentKey: $experimentKey, isAnonymous: $isAnonymous)
					@skip(if: $isProjectListSidebarExperimentEnabled)
				...refinementBar_projectsDirectoryV3_Filters @arguments(isAnonymous: $isAnonymous)
				...projectsList_projectsDirectoryV3_rootQueryRef @arguments(isAnonymous: $isAnonymous)
				...pageLayout_projectsDirectoryV3_ProjectDirectoryLayout
					@arguments(isAdminSettingsContext: $isAdminSettingsContext)
				...emptyView_projectsDirectoryV3
				...noPermissionViews_projectsDirectoryV3
				jira {
					canBrowseProjects: permission(cloudId: $cloudId, type: BROWSE_PROJECTS)
						@optIn(to: "JiraPermission") {
						hasPermission
					}
				}
				...rightPanel_projectsDirectoryV3_ShouldShowRightSidebar
					@skip(if: $skipShouldShowRightSidebar)
					@include(if: $isProjectListSidebarExperimentEnabled)
			}
		`,
		queryRef,
	);
	/* eslint-enable @atlassian/relay/must-colocate-fragment-spreads */

	const [dataRef, refetch] = useRefetchableFragment<
		ProjectDirectoryRefetchQuery,
		pageLayout_projectsDirectoryV3_ProjectDirectoryLayout$key
	>(
		graphql`
			fragment pageLayout_projectsDirectoryV3_ProjectDirectoryLayout on Query
			@argumentDefinitions(isAdminSettingsContext: { type: "Boolean!" })
			@refetchable(queryName: "ProjectDirectoryRefetchQuery") {
				jira {
					allJiraProjects(cloudId: $cloudId, filter: $filter, first: $first, last: $last) {
						...projectsList_projectsDirectoryV3_projectsRef
							@arguments(isAdminSettingsContext: $isAdminSettingsContext)
						totalCount
					}
				}
			}
		`,
		rootQueryRef,
	);

	const prevDataRef = usePrevious(dataRef);

	const [{ isFetching, errors }, { refetchData }] = useDirectoryRefetch({
		refetch,
		query: RefetchProjectsQuery,
	});

	const handleFilterUpdate = useCallback(
		(filter: FilterState) => {
			const { [FilterFields.Page]: currentPage } = filter;

			refetchData({
				variables: {
					cloudId,
					filter: mapQueryToFilter(filter, { cloudId }),
					first: DEFAULT_LIMIT * parseInt(currentPage, 10),
					last: DEFAULT_LIMIT,
					isAdminSettingsContext,
				},
				options: { fetchPolicy: 'network-only' },
			});
			syncQueryFilter(filter);
		},
		[cloudId, refetchData, syncQueryFilter, isAdminSettingsContext],
	);

	const isSiteAdmin = useIsSiteAdmin();
	const isJiraAdmin = useIsAdmin();
	const isAnonymous = useIsAnonymous();

	const canBrowseProjects =
		isSiteAdmin || isJiraAdmin || rootQueryRef?.jira?.canBrowseProjects?.hasPermission;

	if (!canBrowseProjects) {
		return (
			<HeaderlessContainer>
				<AsyncNoPermissionViews dataRef={rootQueryRef} />;
				<PlatformExperienceSuccess
					experience={experienceKey}
					attributes={{ view: 'no-permission' }}
				/>
			</HeaderlessContainer>
		);
	}

	if (errors) {
		onFail('projects-directory-v3.page', errors);
		return (
			<HeaderlessContainer>
				<PageErrorState />
			</HeaderlessContainer>
		);
	}

	return (
		<JSErrorBoundary id="page-layout" packageName="jiraProjectsDirectoryV3" onError={onFail}>
			<ScrollablePage>
				<FilterContainer isGlobal onUpdate={handleFilterUpdate}>
					<DocumentTitle
						title={
							isAdminSettingsContext
								? formatMessage(messages.adminDocumentTitle)
								: formatMessage(messages.documentTitle)
						}
					/>
					<Header dataRef={rootQueryRef} isAdminSettingsContext={isAdminSettingsContext} />
					{!expValEquals(
						'contextual_bandits_reccs_in_projects_directory',
						'cohort',
						'variation',
					) && <TopTemplatePicker dataRef={rootQueryRef} />}

					<RefinementBar dataRef={rootQueryRef} />

					<AnnouncerV2
						message={formatMessage(messages.filterResultsAnnouncement, {
							itemCount: dataRef?.jira?.allJiraProjects?.totalCount || 0,
						})}
						shouldAnnounce={!isFetching && prevDataRef !== dataRef}
					/>

					{dataRef?.jira?.allJiraProjects && dataRef?.jira?.allJiraProjects?.totalCount ? (
						<>
							<ProjectsList
								isLoading={isFetching}
								isAdminSettingsContext={isAdminSettingsContext}
								projectsRef={dataRef?.jira?.allJiraProjects}
								rootQueryRef={rootQueryRef}
							/>
							<PlatformExperienceSuccess
								experience={experienceKey}
								attributes={{ view: 'projects-list' }}
							/>
						</>
					) : (
						<>
							<AsyncEmptyView dataRef={rootQueryRef} />
							<PlatformExperienceSuccess
								experience={experienceKey}
								attributes={{ view: 'empty' }}
							/>
						</>
					)}
				</FilterContainer>
			</ScrollablePage>
			<SpaStatePageReady />
			<SubmitApdex
				appName="projects-directory-v3"
				metricKey="jira.directories.projects-v3"
				isExpectedToHaveSsr
				// @ts-expect-error - TS2322
				metric={pageLoad}
			/>
			<SubProductUpdater subProduct="platform" />
			{!isAnonymous &&
				!ff('server-render-projects-directory-sidebar_rj9ki') &&
				expValEquals('contextual_bandits_reccs_in_projects_directory', 'cohort', 'variation') && (
					<ShouldShowRightSidebar dataRef={rootQueryRef} />
				)}
		</JSErrorBoundary>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderlessContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'padding-top': `${gridSize * 8}px`,
});
