import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import Button from '@atlaskit/button';
import DropdownMenu, {
	DropdownItemGroup,
	DropdownItem,
	type DropdownItemProps,
} from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { ModalTransition } from '@atlaskit/modal-dialog';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import {
	type ProjectType,
	SOFTWARE_PROJECT,
	JIRA_PROJECT_TYPE_SOFTWARE_PROJECT,
	JIRA_PROJECT_TYPE_CORE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import {
	useEntryPoint,
	type EntryPointReferenceSubject,
	type RuntimePropsOfEntryPoint,
	type EntryPointActions,
} from '@atlassian/jira-entry-point';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { mergeRefs } from '@atlassian/jira-merge-refs';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	isFreeEdition,
	isStandardEdition,
} from '@atlassian/jira-project-archive-trigger/src/ui/utils';
import ProjectRestoreModal from '@atlassian/jira-project-restore-modal';
import { archiveProjectFeatureGateModalEntryPoint } from '@atlassian/jira-projects-directory-v3-archive-project-feature-gate-modal/entrypoint';
import { archiveProjectModalEntryPoint } from '@atlassian/jira-projects-directory-v3-archive-project-modal/entrypoint';
import { deleteProjectModalEntryPoint } from '@atlassian/jira-projects-directory-v3-delete-project-modal/entrypoint';
import type { actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef$key } from '@atlassian/jira-relay/src/__generated__/actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef.graphql';
import type { actionsCell_projectsDirectoryV3_ActionsCellDropdown_project$key } from '@atlassian/jira-relay/src/__generated__/actionsCell_projectsDirectoryV3_ActionsCellDropdown_project.graphql';
import type { actionsCell_projectsDirectoryV3_project$key } from '@atlassian/jira-relay/src/__generated__/actionsCell_projectsDirectoryV3_project.graphql';
import { type LinkProps, Link } from '@atlassian/jira-router';
import { useModalDialogActions } from '@atlassian/jira-software-modal-dialog';
import { useAppEditions, useIsAdmin } from '@atlassian/jira-tenant-context-controller';
import { useFilter } from '../../../../../../controllers/filter-state';
import { ArchiveProjectDropdownItem } from './archive-project-menu-item';
import { TrashProjectDropdownItem } from './delete-project-menu-item';
import messages from './messages';
import { getSettingsUrl } from './utils';

interface Props {
	project: actionsCell_projectsDirectoryV3_ActionsCellDropdown_project$key;
	jiraQueryRef: actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef$key | null;
}

type ModalEntryPointType =
	| typeof deleteProjectModalEntryPoint
	| typeof archiveProjectModalEntryPoint
	| typeof archiveProjectFeatureGateModalEntryPoint;

type ProjectToRestore = {
	isProjectSimplified: boolean;
	projectName: string;
	projectType: ProjectType;
	projectId: string;
	projectInactiveDate: string;
	projectKey: string;
};

const PREMIUM = 'PREMIUM';

function useModalReturnFocusTo({
	projectToRestore,
}: {
	projectToRestore: ProjectToRestore | null;
}) {
	const { setReturnFocusTo } = useModalDialogActions();
	const renderButtonRef = useRef<HTMLButtonElement | null>(null);

	const setModalReturnFocusTo = useCallback(() => {
		setReturnFocusTo(renderButtonRef);
	}, [setReturnFocusTo, renderButtonRef]);

	useEffect(() => {
		if (projectToRestore) {
			setModalReturnFocusTo();
		}
	}, [projectToRestore, setModalReturnFocusTo, setReturnFocusTo]);

	return { setModalReturnFocusTo, renderButtonRef };
}

const ActionsCellDropdown = ({ project, jiraQueryRef }: Props) => {
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'resultsTable',
	});

	const isProjectArchivingStandardInstanceFFEnabled = ff(
		'arj-project-archiving-feature-gate_z01zt',
	);

	const isAdmin = useIsAdmin();
	const appEditions = useAppEditions();
	const [, { forceUpdate }] = useFilter();
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const [projectToRestore, setProjectToRestore] = useState<ProjectToRestore | null>(null);
	const [activeEntryPoint, setActiveEntryPoint] = useState<{
		runtimeProps: RuntimePropsOfEntryPoint<ModalEntryPointType>;
		entryPointReferenceSubject: EntryPointReferenceSubject<ModalEntryPointType>;
		entryPointActions: EntryPointActions;
		id: string;
	} | null>(null);
	const { jiraProjectType, jiraProjectName, projectStyle, key, id } = useFragment(
		graphql`
			fragment actionsCell_projectsDirectoryV3_ActionsCellDropdown_project on JiraProject {
				jiraProjectType: projectType
				projectStyle
				jiraProjectName: name
				key
				id
			}
		`,
		project,
	);

	const jwmNavigationData =
		useFragment<actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef$key>(
			graphql`
				fragment actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef on JiraQuery
				@argumentDefinitions(cloudId: { type: "ID!" }) {
					jwmLicensing(cloudId: $cloudId) {
						currentUserSeatEdition
					}
				}
			`,
			jiraQueryRef,
		);

	const { setModalReturnFocusTo, renderButtonRef } = useModalReturnFocusTo({ projectToRestore });

	const isCorePremiumUserSeat = Boolean(
		jwmNavigationData?.jwmLicensing?.currentUserSeatEdition === PREMIUM,
	);

	const entryPointParams = useMemo(() => ({ projectId: id }), [id]);
	const { entryPointReferenceSubject: deleteRefSubject, entryPointActions: deleteActions } =
		useEntryPoint(
			deleteProjectModalEntryPoint,
			entryPointParams,
			undefined, // TODO: add performance metrics
			{
				timeToIntent: 50,
			},
		);

	const deleteEntryPointRuntimeProps = useMemo(
		() => ({
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onClose: () => {},
			onDeleteProjectSuccess: () => {
				forceUpdate();
				deleteActions.unload();
			},
			onProjectRestore: setProjectToRestore,
		}),
		[deleteActions, forceUpdate],
	);

	const deleteTriggerRef = useEntryPointButtonTrigger(deleteActions);

	const { entryPointReferenceSubject: archiveRefSubject, entryPointActions: archiveActions } =
		useEntryPoint(
			archiveProjectModalEntryPoint,
			entryPointParams,
			undefined, // TODO: add performance metrics
			{
				timeToIntent: 50,
			},
		);

	const archiveEntryPointRuntimeProps = useMemo(
		() => ({
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onClose: () => {},
			onArchiveProjectSuccess: () => {
				forceUpdate();
				archiveActions.unload();
			},
			onProjectRestore: setProjectToRestore,
		}),
		[archiveActions, forceUpdate],
	);

	const archiveTriggerRef = useEntryPointButtonTrigger(archiveActions);

	const {
		entryPointReferenceSubject: archiveProjectFeatureGateRefSubject,
		entryPointActions: archiveProjectFeatureGateActions,
	} = useEntryPoint(archiveProjectFeatureGateModalEntryPoint, {});

	const archiveFeatureGateEntryPointRuntimeProps = useMemo(
		() => ({
			onClose: () => {
				archiveProjectFeatureGateActions.unload();
			},
		}),
		[archiveProjectFeatureGateActions],
	);

	const archiveFeatureGateTriggerRef = useEntryPointButtonTrigger(archiveProjectFeatureGateActions);

	const handleTrashClick = useCallback(() => {
		setModalReturnFocusTo();
		setActiveEntryPoint({
			entryPointReferenceSubject: deleteRefSubject,
			runtimeProps: deleteEntryPointRuntimeProps,
			entryPointActions: deleteActions,
			id: 'project-delete-modal',
		});
		fireUIAnalytics(analyticsEvent, 'trashDropdownItem');
	}, [
		setModalReturnFocusTo,
		deleteRefSubject,
		deleteEntryPointRuntimeProps,
		deleteActions,
		analyticsEvent,
	]);

	const isProjectArchiveStandardEnabled =
		isProjectArchivingStandardInstanceFFEnabled && isStandardEdition(SOFTWARE_PROJECT, appEditions);
	const isProjectArchiveFreeEnabled =
		fg('project_archive_feature_gate_in_free_instances') &&
		isFreeEdition(SOFTWARE_PROJECT, appEditions);

	const handleArchiveClick = useCallback(() => {
		setModalReturnFocusTo();
		if (
			(isProjectArchiveStandardEnabled || isProjectArchiveFreeEnabled) &&
			(jiraProjectType === JIRA_PROJECT_TYPE_SOFTWARE_PROJECT ||
				jiraProjectType === JIRA_PROJECT_TYPE_CORE_PROJECT)
		) {
			setActiveEntryPoint({
				entryPointReferenceSubject: archiveProjectFeatureGateRefSubject,
				runtimeProps: archiveFeatureGateEntryPointRuntimeProps,
				entryPointActions: archiveProjectFeatureGateActions,
				id: 'project-archive-feature-gate-modal',
			});

			fireUIAnalytics(analyticsEvent, 'projectArchivingFeatureGateItem');
		} else {
			setActiveEntryPoint({
				entryPointReferenceSubject: archiveRefSubject,
				runtimeProps: archiveEntryPointRuntimeProps,
				entryPointActions: archiveActions,
				id: 'project-archive-modal',
			});
			fireUIAnalytics(analyticsEvent, 'archiveDropdownItem');
		}
	}, [
		setModalReturnFocusTo,
		isProjectArchiveStandardEnabled,
		isProjectArchiveFreeEnabled,
		jiraProjectType,
		archiveProjectFeatureGateRefSubject,
		archiveFeatureGateEntryPointRuntimeProps,
		archiveProjectFeatureGateActions,
		analyticsEvent,
		archiveRefSubject,
		archiveEntryPointRuntimeProps,
		archiveActions,
	]);

	const handleSettingsClick = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(_e: any, _analyticsEvent: AnalyticsEvent) => {
			setIsDropdownOpen(false);
			fireUIAnalytics(analyticsEvent, 'settingsDropdownItem');
		},
		[analyticsEvent],
	);

	const handleDropdownClick = useCallback(
		({ isOpen }: { isOpen: boolean }) => {
			setIsDropdownOpen(isOpen);
			fireUIAnalytics(analyticsEvent, 'dropdownMenu');
		},
		[analyticsEvent],
	);

	const isSimplified = projectStyle === 'TEAM_MANAGED_PROJECT';
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const projectType = jiraProjectType?.toLowerCase() as ProjectType;

	const archiveTriggerRefToUse =
		(jiraProjectType === JIRA_PROJECT_TYPE_SOFTWARE_PROJECT ||
			jiraProjectType === JIRA_PROJECT_TYPE_CORE_PROJECT) &&
		(isProjectArchiveStandardEnabled || isProjectArchiveFreeEnabled)
			? archiveFeatureGateTriggerRef
			: archiveTriggerRef;

	return (
		<ActionCellWrapper>
			{activeEntryPoint !== null && (
				<ModalEntryPointContainer
					{...activeEntryPoint}
					id={id}
					packageName={`jira-projects-directory-v3-delete-${id}`}
				/>
			)}
			{projectToRestore !== null && (
				<ModalTransition>
					<ProjectRestoreModal
						{...projectToRestore}
						onProjectRestoreSuccess={() => {
							setProjectToRestore(null);
							forceUpdate();
						}}
						onClose={() => setProjectToRestore(null)}
						onProjectRestoreFailure={() => setProjectToRestore(null)}
					/>
				</ModalTransition>
			)}
			<DropdownMenu
				placement="bottom-end"
				isOpen={isDropdownOpen}
				onOpenChange={handleDropdownClick}
				shouldRenderToParent
				trigger={({ triggerRef, ...props }) => (
					<Button
						{...props}
						appearance="subtle"
						aria-label={formatMessage(messages.moreActions, {
							name: jiraProjectName,
						})}
						iconBefore={<MoreIcon label="" />}
						ref={mergeRefs(triggerRef, renderButtonRef)}
					/>
				)}
			>
				<DropdownItemGroup>
					<DropdownItem
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						onClick={handleSettingsClick as DropdownItemProps['onClick']}
						component={({ onClick, ...props }) => (
							<Link
								{...props}
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								onClick={onClick as LinkProps['onClick']}
								to={getSettingsUrl(projectType, isSimplified, key)}
							/>
						)}
					>
						{formatMessage(messages.settings)}
					</DropdownItem>
					<TrashProjectDropdownItem
						projectType={projectType}
						isAdmin={isAdmin}
						canAdministerProject
						isSimplified={isSimplified}
						ref={deleteTriggerRef}
						onClick={handleTrashClick}
					/>
					<ArchiveProjectDropdownItem
						projectType={projectType}
						isAdmin={isAdmin}
						canAdministerProject
						isSimplified={isSimplified}
						ref={archiveTriggerRefToUse}
						onClick={handleArchiveClick}
						isCorePremiumUserSeat={isCorePremiumUserSeat}
					/>
				</DropdownItemGroup>
			</DropdownMenu>
		</ActionCellWrapper>
	);
};

interface ActionCellProps {
	project: actionsCell_projectsDirectoryV3_project$key;
	jiraQueryRef: actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef$key | null;
}

export const ActionsCell = ({ project, jiraQueryRef }: ActionCellProps) => {
	const dataRef = useFragment<actionsCell_projectsDirectoryV3_project$key>(
		graphql`
			fragment actionsCell_projectsDirectoryV3_project on JiraProject {
				canEdit: action(type: EDIT_PROJECT_CONFIG) {
					canPerform
				}
				...actionsCell_projectsDirectoryV3_ActionsCellDropdown_project
			}
		`,
		project,
	);

	if (!dataRef?.canEdit?.canPerform) {
		return null;
	}

	return <ActionsCellDropdown project={dataRef} jiraQueryRef={jiraQueryRef} />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionCellWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
});
