import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import ChevronDownIconLegacy from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIconLegacy from '@atlaskit/icon/glyph/chevron-up';
import LightbulbFilledIcon from '@atlaskit/icon/glyph/lightbulb-filled';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/chevron-up';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	type AnalyticsAttributes,
	fireUIAnalytics,
	useAnalyticsEvents,
	FireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew$key } from '@atlassian/jira-relay/src/__generated__/topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew.graphql';
import { useExpandedState } from '../../controllers/expand-state';
import messages from './messages';
import { TemplateGalleryNew } from './template-gallery';

// Not renaming as this will add an unnecessary risk, to rename it to TopTemplatePicker will required the renaming behind a feature flag
export const TopTemplatePickerNew = ({
	dataRef,
	attributes,
}: {
	dataRef: topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew$key;
	attributes?: AnalyticsAttributes;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isExpanded, setIsExpanded } = useExpandedState();
	const { formatMessage } = useIntl();

	const data = useFragment<topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew$key>(
		graphql`
			fragment topTemplatePicker_projectListTemplatePicker_TopTemplatePickerNew on JiraQuery
			@argumentDefinitions(experimentKey: { type: "String" }) {
				projectListViewTemplates(experimentKey: $experimentKey, cloudId: $cloudId)
					@optIn(to: "JiraProjectListViewTemplate") {
					edges {
						__typename
					}
				}
				...templateGallery_projectListTemplatePicker_TemplateGalleryNew
					@arguments(experimentKey: $experimentKey)
			}
		`,
		dataRef,
	);

	const numberOfTemplates = data?.projectListViewTemplates?.edges?.length;

	if (!numberOfTemplates || numberOfTemplates <= 1) {
		return null;
	}

	return (
		<>
			<Wrapper>
				<Header>
					<TitleWrapper>
						{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
						<LightbulbFilledIcon
							label=""
							primaryColor={token('color.background.accent.orange.subtle', '#FAA53D')}
						/>
						<Heading>{formatMessage(messages.recommendedTemplatesTitle)}</Heading>
					</TitleWrapper>
					<Button
						appearance="subtle-link"
						onClick={() => {
							setIsExpanded(!isExpanded);
							fireUIAnalytics(
								createAnalyticsEvent({
									action: 'clicked',
									actionSubject: 'button',
								}),
								'toggleGallery',
								{
									action: !isExpanded ? 'open' : 'close',
								},
							);
						}}
						iconAfter={
							isExpanded ? (
								<ChevronUpIcon
									color="currentColor"
									LEGACY_primaryColor={token('color.text.subtle', '#44546F')}
									label=""
									LEGACY_fallbackIcon={ChevronUpIconLegacy}
								/>
							) : (
								<ChevronDownIcon
									color="currentColor"
									LEGACY_primaryColor={token('color.text.subtle', '#44546F')}
									label=""
									LEGACY_fallbackIcon={ChevronDownIconLegacy}
								/>
							)
						}
					>
						{isExpanded ? formatMessage(messages.hideGallery) : formatMessage(messages.showGallery)}
					</Button>
				</Header>
				<TemplateGalleryNew dataRef={data} isOpen={isExpanded} attributes={attributes} />
			</Wrapper>
			<FireTrackAnalytics
				eventName="topTemplatePicker viewed"
				attributes={{ isOpen: isExpanded }}
			/>
		</>
	);
};

const SIDE_OFFSET = 40;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.header({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div({
	font: token('font.body'),
	fontWeight: token('font.weight.medium', '500'),
	display: 'flex',
	margin: 0,
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	margin: `0 -${SIDE_OFFSET}px ${token('space.400', '32px')} -${SIDE_OFFSET}px`,
	padding: `${token('space.200', '16px')} ${16 + SIDE_OFFSET}px ${token('space.100', '8px')}`,
	backgroundColor: token('color.background.neutral', '#FAFBFC'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Heading = styled.h4({
	margin: '0',
	marginLeft: token('space.050', '4px'),
});
