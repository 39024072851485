import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { UserProfile } from '@atlassian/jira-directory-base-v3/src/ui/common/user-profile';
import type { leadCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/leadCell_projectsDirectoryV3.graphql';

interface Props {
	project: leadCell_projectsDirectoryV3$key;
}

export const LeadCell = ({ project }: Props) => {
	const data = useFragment(
		graphql`
			fragment leadCell_projectsDirectoryV3 on JiraProject {
				lead @optIn(to: "JiraLead") {
					...userProfile_directoryBaseV3
				}
			}
		`,
		project,
	);

	return data?.lead ? <UserProfile dataRef={data.lead} /> : null;
};
